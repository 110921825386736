// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCgftGBFE7o5XAHD7Hj_IWgybBuaBRRa_E",
    authDomain: "nodo-electronico.firebaseapp.com",
    databaseURL: "https://nodo-electronico.firebaseio.com",
    projectId: "nodo-electronico",
    storageBucket: "nodo-electronico.appspot.com",
    messagingSenderId: "795914117603",
    appId: "1:795914117603:web:0547a6b9c97afb1aa280d6",
    measurementId: "G-ZHKMT524PK"
  },
  paypal: {
    clientID: 'AUy7-uAX2xla7njyFX3LzXYoD9QhGLdapfc3Sdk4lr-sA5mtFkcbkn-fsOn3guzw-FDkbFT-yofizUca'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
