import { Component, OnInit } from '@angular/core';
import { Globals } from '../app-globals';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AngularFirestore } from '@angular/fire/firestore';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { ElectronicComponentViewerDialog } from '../electronic-component-viewer-dialog/electronic-component-viewer-dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(300)
      ]),
      transition(':leave',
        animate(300, style({opacity: 0})))
    ])
  ]
})
export class ProductDetailsComponent implements OnInit {
  public total: number = 0;
  public product: any;
  public productID: string = '';

  constructor(
    public route: ActivatedRoute,
    public globals: Globals,
    public firestore: AngularFirestore,
    public sanitizer: DomSanitizer,
    public snackBar: MatSnackBar,
    public dialog: MatDialog ) { }

  async ngOnInit() {
    this.total = 0;
    this.globals.cart.forEach((product) => {
      this.total = this.total + (product.price * product.quantity);
    });
    this.route.paramMap.subscribe( async(params) => {
      this.productID = params.get('productID');
      this.product = (await this.firestore.collection('products', ref => ref.where('path','==',this.productID)).snapshotChanges().pipe(take(1)).toPromise()).pop().payload.doc.data();
      this.product.id = this.productID;
    });
  }

  addToCart(product) {
    let alreadyAdded: boolean = false;
    let productIndex: number = 0;
    this.globals.cart.forEach((cartProduct, index) => {
      if(cartProduct.mpn == product.mpn) {
        productIndex = index;
        alreadyAdded = true;
      }
    });
    if(alreadyAdded) {
      this.modifyProductAmount(productIndex,1);
    }
    else {
      product.quantity = 1;
      this.globals.cart.push(product);
      this.total = 0;
      this.globals.cart.forEach((product) => {
        this.total = this.total + (product.price * product.quantity);
      });
    }
    this.snackBar.open('Se agregó ' + product.name + ' al carrito', 'OK', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: 'success-snack-bar'
    })
  }

  modifyProductAmount(index: number, amount: number) {
    if(this.globals.cart[index].quantity >= this.globals.cart[index].stock && amount == 1) {
      this.snackBar.open('Ya alcanzaste el límite de productos disponible','OK', { duration: 3000, horizontalPosition: 'right', verticalPosition: 'top' });
      return;
    }
    this.globals.cart[index].quantity = this.globals.cart[index].quantity + amount;
    this.total = 0;
    this.globals.cart.forEach((product) => {
      this.total = this.total + (product.price * product.quantity);
    });
  }

  removeProduct(index: number) {
    this.globals.cart.splice(index,1);
    this.total = 0;
      this.globals.cart.forEach((product) => {
        this.total = this.total + (product.price * product.quantity);
      });
    this.snackBar.open('Se quitó el producto del carrito', 'OK', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: 'error-snack-bar'
    });
  }

  viewElectronicComponent(component) {
    let dialogClasses: string[];
    if(this.globals.darkTheme) {
      dialogClasses = ['dark-dialog', 'dark-theme'];
    }
    else {
      dialogClasses = ['light-dialog']
    }
    this.dialog.open(ElectronicComponentViewerDialog, {
      autoFocus: false,
      data: component,
      width: '960px',
      maxWidth: '95vw',
      panelClass: dialogClasses
    });
  }

}
