<mat-tab-group color="accent" class="course-mat-tab" animationDuration="0ms" (focusChange)="tabChanged($event)" selectedIndex="1">
  <mat-tab>
    <ng-template mat-tab-label style="color: #EEEEEE !important;">
      <mat-icon class="icon-margin">list</mat-icon>
      Contenido del Curso
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label style="color: #EEEEEE !important;">
      <mat-icon class="icon-margin">class</mat-icon>
      Lección
    </ng-template>
    <mat-spinner *ngIf="downloading" style="margin:16% auto;" [@fadeAnimation]="'in'"></mat-spinner>
    <section [@fadeAnimation]="'in'" *ngIf="!downloading">
      <div class="video-block" *ngIf="lesson?.videoURL">
        <div class="video-container">
          <div class="lesson-video-wrapper">
            <iframe allowtransparency="true" style="background-color: #000000;" [src]="lessonVideoSafeURL" class="lesson-video" width="100%" height="auto" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <br>
      <div fxLayout="row wrap" class="lesson-container">
        <div fxFlex="100%">
          <h1 class="title">{{ lesson?.title }}</h1>
          <div *ngFor="let block of lesson?.content">
            <h2 *ngIf="block.type == 'subtitle'" class="subtitle">{{ block.text }}</h2>
            <p *ngIf="block.type == 'plainText'" class="text">{{ block.text }}</p>
            <div *ngIf="block.type == 'HTMLText'" [innerHTML]="block.text" class="text"></div>
            <div class="text-center"><a mat-stroked-button color="accent" *ngIf="block.type == 'link'" href="{{ block.url }}" target="_blank">{{ block.text }}</a></div>
            <p *ngIf="block.type == 'image'" class="text-center">
              <img src="{{ block.url }}" alt="{{ block.fileName }}" style="border-radius: 8px;" class="mat-elevation-z16" [ngStyle]="{'width': block.width}">
            </p>
            <mat-list *ngIf="block.type == 'bom'">
              <mat-list-item matRipple *ngFor="let component of block.components" (click)="viewElectronicComponent(component)" class="electronic-component">
                <img matListAvatar src="{{ component.thumbnail.url }}" alt="...">
                <p matLine class="text" style="line-height: 1.2rem; margin-top: 6px;">
                  <strong>{{ component.name }}</strong>
                </p>
                <p matLine class="text" style="opacity: 0.7;">
                  {{ component.quantity }} pieza<span *ngIf="component.quantity > 1">s</span>
                </p>
              </mat-list-item>
            </mat-list>
            <div *ngIf="block.type == 'latex'">
              <ng-katex [equation]="block.code" [options]="latexOptions"></ng-katex>
            </div>
            <textarea highlight-js [options]="{ languages: ['arduino'] }" *ngIf="block.type == 'hl-code'">
              {{ block.code }}
            </textarea>
          </div>
          <p class="text-center">
            <button mat-flat-button color="accent" (click)="finishLesson()" style="width: 300px; max-width: 100%;">
              Finalizar Sesión
            </button>
          </p>
        </div>
      </div>
    </section>
  </mat-tab>
</mat-tab-group>
