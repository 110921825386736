import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'user-avatar-dialog',
  templateUrl: './user-avatar-dialog.html',
  styleUrls: ['./user-avatar-dialog.scss']
})
export class UserAvatarDialog {

  public robotColors: string[] = [];

  public robot1Colors = [
    '../../assets/users/robot-1-azul-claro.png',
    '../../assets/users/robot-1-azul-oscuro.png',
    '../../assets/users/robot-1-negro.png',
    '../../assets/users/robot-1-rojo.png',
    '../../assets/users/robot-1-rosa.png',
    '../../assets/users/robot-1-verde.png'
  ];
  public robot2Colors = [
    '../../assets/users/robot-2-azul-claro.png',
    '../../assets/users/robot-2-azul-oscuro.png',
    '../../assets/users/robot-2-negro.png',
    '../../assets/users/robot-2-rojo.png',
    '../../assets/users/robot-2-rosa.png',
    '../../assets/users/robot-2-verde.png'
  ];
  public robot3Colors = [
    '../../assets/users/robot-3-azul.png',
    '../../assets/users/robot-3-naranja.png',
    '../../assets/users/robot-3-negro.png',
    '../../assets/users/robot-3-rojo.png',
    '../../assets/users/robot-3-rosa.png',
    '../../assets/users/robot-3-verde.png'
  ];
  public robot4Colors = [
    '../../assets/users/robot-4-azul-claro.png',
    '../../assets/users/robot-4-azul-oscuro.png',
    '../../assets/users/robot-4-gris.png',
    '../../assets/users/robot-4-rojo.png',
    '../../assets/users/robot-4-rosa.png',
    '../../assets/users/robot-4-verde.png'
  ];


  constructor(
    public dialogRef: MatDialogRef<UserAvatarDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  showRobotColors(robots: string[], stepper: MatStepper) {
    this.robotColors = robots;
    stepper.next();
  }

  selectRobot(robot) {
    this.dialogRef.close(robot);
  }
}