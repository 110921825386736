import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AngularFirestore } from '@angular/fire/firestore';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Globals } from "../app-globals";
import { take } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ElectronicComponentViewerDialog } from '../electronic-component-viewer-dialog/electronic-component-viewer-dialog';
import { LessonTestDialog } from './lesson-test-dialog/lesson-test-dialog';

@Component({
  selector: 'app-course-lesson',
  templateUrl: './course-lesson.component.html',
  styleUrls: ['./course-lesson.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(300)
      ]),
      transition(':leave',
        animate(300, style({opacity: 0})))
    ])
  ]
})
export class CourseLessonComponent implements OnInit, AfterViewChecked {
  public courseID;
  public lessonID;
  public course: any;
  public lesson: any;
  public downloading: boolean = true;
  public lessonVideoSafeURL: SafeResourceUrl;
  public userLessonsCompleted: any[];
  public shareWhatsAppURL: SafeResourceUrl;
  public latexOptions = { displayMode: true };

  /* Chat */
  public messageText: string = '';
  public chatMessages: any[] = [];
  public lastChatMessages: any[] = [];
  public viewUpdated: boolean = false;
  public gotAllMessages: boolean = false;
  @ViewChild('messagesContainer') private messagesContainer: ElementRef;

  constructor(
    public route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public router: Router,
    public dialog: MatDialog,
    public firestore: AngularFirestore,
    public firedb: AngularFireDatabase,
    public sanitizer: DomSanitizer,
    public globals: Globals ) { }

  ngOnInit() {
    this.globals.userObservable.subscribe(() => {
      this.downloading = false;
      let now = Date.now();
      if(this.globals.userSubscription < now || this.globals.userSubscription == null) {
        this.snackBar.open('Lo sentimos, tu suscripción a terminado', 'OK', { duration: 10000, panelClass: 'error-snack-bar', horizontalPosition: 'center', verticalPosition: 'top' });
        this.router.navigateByUrl('suscripcion');
      }
      else {
        this.route.paramMap.subscribe( async(params) => {
          this.downloading = true;
          this.courseID = params.get('courseID');
          this.lessonID = params.get('lessonID');
          let courseData = await this.firestore.collection('courses', ref => ref.where('routeID','==',this.courseID)).snapshotChanges().pipe(take(1)).toPromise();
          if(courseData.length > 0) {
            this.course = courseData[0].payload.doc.data();
            this.course.id = courseData[0].payload.doc.id;
            this.firestore.collection('courses').doc(this.course.id).collection('lessons', lessonRef => lessonRef.where('path','==',this.lessonID)).valueChanges().subscribe(async (lessonData) => {
              this.lesson = lessonData[0];
              this.lessonVideoSafeURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.lesson.videoURL);
              this.lesson.content.forEach((block) => {
                if(block.type == 'code') {
                  block.secureCode = this.sanitizer.bypassSecurityTrustResourceUrl(block.code);
                }
              });
              this.firedb.list('messages/' + this.globals.userUID, ref => ref.orderByChild('date').limitToLast(10)).valueChanges(['child_added']).subscribe((messages) => {
                if(messages.length < 10) {
                  this.gotAllMessages = true;
                }
                this.chatMessages = messages;
                this.viewUpdated = false;
                if(this.globals.newReceivedMessages) {
                  this.firestore.collection('users').doc(this.globals.userUID).update({ newReceivedMessages: false });
                }
              });
              this.downloading = false;
              let userCourseDoc = await this.firestore.collection('users').doc(this.globals.userUID).collection('courses').doc(this.course.id).ref.get();
              if(!userCourseDoc.exists) {
                this.firestore.collection('users').doc(this.globals.userUID).collection('courses').doc(this.course.id).set({
                  path: this.course.routeID,
                  title: this.course.title,
                  logo: this.course.logoURL,
                  lessonsCompleted: [],
                  lastLesson: {
                    title: this.lesson.title,
                    path: this.lesson.path,
                    date: Date.now(),
                    thumbnail: this.lesson.thumbnailImage.url
                  }
                });
              }
              else {
                userCourseDoc.ref.update({
                  lastLesson: {
                    title: this.lesson.title,
                    path: this.lesson.path,
                    date: Date.now(),
                    thumbnail: this.lesson.thumbnailImage.url
                  }
                });
                this.userLessonsCompleted = userCourseDoc.data().lessonsCompleted;
              }
            });
          }
        });
      }
    });
  }
  
  sendMessage() {
    this.firedb.list('messages/' + this.globals.userUID).push({
      type: 'text-sent',
      text: this.messageText,
      date: Date.now()
    });
    if(!this.globals.newSentMessages) {
      this.firestore.collection('users').doc(this.globals.userUID).update({ newSentMessages: true, lastMessageDate: Date.now(), lastMessageText: this.messageText });
    }
    this.messageText = '';
    this.viewUpdated = false;
  }

  getMoreMessages() {
    let lastMessageDate = 0;
    if(this.lastChatMessages.length == 0) {
      lastMessageDate = this.chatMessages[0].date;
    }
    else {
      lastMessageDate = this.lastChatMessages[0].date;
    }
    this.firedb.list('messages/' + this.globals.userUID, ref => ref.orderByChild('date').endAt(lastMessageDate).limitToLast(11)).valueChanges().subscribe((messages) => {
      if(messages.length < 11) {
        this.gotAllMessages = true;
      }
      messages.splice((messages.length - 1),1);
      this.lastChatMessages.unshift(...messages);
      console.log(this.lastChatMessages);
    });
  }

  ngAfterViewChecked() {     
    if(!this.viewUpdated) {
      try {
        this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
      } catch(err) {}
      this.viewUpdated = true;
    }
  }

  tabChanged(event: MatTabChangeEvent) {
    if(event.index == 2) {
      setTimeout(() => {
        try {
          this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
        } catch(err) {}
      },100);
    }
    if(event.index == 0) {
      this.router.navigateByUrl('/cursos/' + this.courseID + '/contenido');
    }
  }

  viewElectronicComponent(component) {
    let dialogClasses: string[];
    if(this.globals.darkTheme) {
      dialogClasses = ['dark-dialog', 'dark-theme'];
    }
    else {
      dialogClasses = ['light-dialog']
    }
    this.dialog.open(ElectronicComponentViewerDialog, {
      autoFocus: false,
      data: component,
      width: '960px',
      maxWidth: '95vw',
      panelClass: dialogClasses
    });
  }

  finishLesson() {
    if(this.lesson.question) {
      let testDialogClass: string[] = [];
      if(this.globals.darkTheme) {
        testDialogClass.push('dark-theme')
      }
      this.dialog.open(LessonTestDialog, {
        width: '640px',
        panelClass: testDialogClass,
        autoFocus: false,
        data: {
          question: this.lesson.question,
          answers: this.lesson.answers
        }
      }).afterClosed().subscribe((result) => {
        if(result) {
          this.snackBar.open('¡Muy bien! Respuesta correcta 🎉', 'OK', { duration: 5000, panelClass: 'success-snack-bar', horizontalPosition: 'center', verticalPosition: 'top' }).afterDismissed().subscribe(() => {
            if(this.lesson.isDemo) {
              this.snackBar.open('Haz finalizado la lección Demo, suscríbete para obtener el curso completo', 'OK', { duration: 5000, panelClass: 'dark-snack-bar', horizontalPosition: 'center', verticalPosition: 'top' }).afterDismissed().subscribe(() => {
                this.router.navigateByUrl('/suscripcion');
              });
            }
            else {
              let lessonAlreadyCompleted: boolean = false;
              for (let index = 0; index < this.userLessonsCompleted.length; index++) {
                const lesson = this.userLessonsCompleted[index];
                if(lesson.path == this.lessonID) {
                  lessonAlreadyCompleted = true;
                }
              }
              if(!lessonAlreadyCompleted) {
                this.userLessonsCompleted.push({
                  title: this.lesson.title,
                  path: this.lesson.path,
                  date: Date.now()
                });
                this.firestore.collection('users').doc(this.globals.userUID).collection('courses').doc(this.course.id).update({
                  lessonsCompleted: this.userLessonsCompleted
                });
              }
              let nextLessonFound: boolean = false;
              for (let sectionIndex = 0; sectionIndex < this.course.tableOfContents.length; sectionIndex++) {
                let section = this.course.tableOfContents[sectionIndex];
                for (let index = 0; index < section.lessons.length; index++) {
                  let lesson = section.lessons[index];
                  if(nextLessonFound) {
                    console.log(lesson);
                    this.router.navigateByUrl('/cursos/' + this.courseID + '/leccion/' + lesson.path);
                    return;
                  }
                  if(lesson.path == this.lessonID) {
                    nextLessonFound = true;
                  }
                }
              }
              this.snackBar.open('¡Felicidades! Terminaste el curso 🎉🎉🎉', 'OK', { duration: 5000, panelClass: 'dark-snack-bar', horizontalPosition: 'center', verticalPosition: 'top' })
            }
          });
        }
      });
    }
    else {
      let nextLessonFound: boolean = false;
      for (let sectionIndex = 0; sectionIndex < this.course.tableOfContents.length; sectionIndex++) {
        let section = this.course.tableOfContents[sectionIndex];
        for (let index = 0; index < section.lessons.length; index++) {
          let lesson = section.lessons[index];
          if(nextLessonFound) {
            console.log(lesson);
            this.router.navigateByUrl('/cursos/' + this.courseID + '/leccion/' + lesson.path);
            return;
          }
          if(lesson.path == this.lessonID) {
            nextLessonFound = true;
          }
        }
      }
      this.snackBar.open('¡Felicidades! Terminaste el curso', 'OK', { duration: 5000, panelClass: 'dark-snack-bar', horizontalPosition: 'center', verticalPosition: 'top' });
      this.router.navigateByUrl('/cursos/' + this.courseID + '/contenido');
    }
  }
}
