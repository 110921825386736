import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Subscription } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ResetPasswordDialog } from './reset-password-dialog/reset-password-dialog';
import { Globals } from '../app-globals';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(300)
      ]),
      transition(':leave',
        animate(300, style({opacity: 0})))
    ])
  ]
})
export class LoginComponent implements OnInit {
  public hide: boolean = true;
  public userMail: string = '';
  public userPassword: string = '';
  public userAuthenticated: boolean = false;
  public sesionChecked: boolean = false;
  public authenticating: boolean = false;
  public userObservable: Subscription;
  public redirectRoute: string = '';

  constructor(
    public globals: Globals,
    public fireauth: AngularFireAuth,
    public router: Router,
    public route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public dialog: MatDialog ) { }

  ngOnInit(): void {
    this.globals.darkTheme = false;
    this.userObservable = this.fireauth.authState.subscribe((user) => {
      this.sesionChecked = true;
      if(user != null) {
        this.router.navigate(['/inicio']);
        this.userAuthenticated = true;
      }
    });
    this.route.paramMap.subscribe((params) => {
      if(params)
        this.redirectRoute = params.get('redirectRoute');
    });
  }

  ngOnDestroy() {
    this.userObservable.unsubscribe();
    this.globals.darkTheme = true;
  }

  loginUser(){
    this.authenticating = true;
    this.fireauth.signInWithEmailAndPassword(this.userMail, this.userPassword).then((res) => {
      this.authenticating = false;
      if(this.redirectRoute) {
        this.router.navigateByUrl('/' + this.redirectRoute);
      }
      else {
        this.router.navigateByUrl('/inicio');
      }
    }).catch((err) => {
      this.authenticating = false;
      if(err.code == 'auth/invalid-email') {
        this.snackBar.open('Error, formato correo electrónico inválido', 'OK', {
          duration: 5000,
          panelClass: 'error-snack-bar',
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.userMail = '';
        this.userPassword = '';
      }

      if(err.code == 'auth/user-not-found') {
        this.snackBar.open('Error, cuenta de usuario no existe', 'OK', {
          duration: 5000,
          panelClass: 'error-snack-bar',
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.userMail = '';
        this.userPassword = '';
      }

      if(err.code == 'auth/wrong-password') {
        this.snackBar.open('Error, contraseña incorrecta', 'OK', {
          duration: 5000,
          panelClass: 'error-snack-bar',
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.userPassword = '';
      }
    });
  }

  resetUserPassword() {
    this.dialog.open(ResetPasswordDialog).afterClosed().subscribe((result) => {
      if(result) {
        this.snackBar.open('Se ha enviado el correo de reinicio de contraseña', 'OK',  { duration: 10000, panelClass: 'dark-snack-bar', horizontalPosition: 'right', verticalPosition: 'top' });
      }
    })
  }

  registerUser() {
    if(this.redirectRoute) {
      this.router.navigateByUrl('/registro/' + this.redirectRoute);
    }
    else {
      this.router.navigateByUrl('/registro');
    }
  }

}
