<div mat-dialog-title class="text-center" style="margin-bottom: 8px;">
  <span class="title" style="line-height: 20px !important;">{{ component.name }}</span>
</div>
<div mat-dialog-content>
  <div [@fadeAnimation]="'in'">
    <div fxLayout="row wrap" fxLayout.xs="column">
      <div fxFlex="216px" fxFlex.lt-md="100%">
        <p class="text-center">
          <img src="{{ component.thumbnail?.url }}" alt="" width="200px" style="border-radius: 16px; margin: 8px">
        </p>
      </div>
      <div fxFlex="calc(100%-216px)" fxFlex.lt-md="100%" style="padding: 8px;">
        <h2 class="subtitle" style="margin-bottom: 4px !important;"><strong>MPN:</strong> {{ component.mpn }} </h2>
        <p class="text" style="margin-top: 4px !important;">{{ component.description }}</p>
        <p class="text" style="margin: 0px;"><strong>Empaquetado: </strong>{{ component.package | electronicComponentParameter:{type: 'package'} }}</p>
        <p class="text" style="margin: 0px;"><strong>Fabricante: </strong>{{ component.manufacturer | electronicComponentParameter:{type: 'manufacturer'} }}</p>
        <p class="text" style="margin-top: 0px;"><strong>Categoría: </strong>{{ component.category[0] | electronicComponentParameter:{type: 'category'} }} / {{ component.subcategory[0] | electronicComponentParameter:{type: 'subcategory'} }}</p>
      </div>
    </div>
    <div fxLayout="row wrap" fxLayout.xs="column" fxFlexAlign="start">
      <div fxFlex="50%" fxFlex.lt-sm="100%" *ngIf="technicalSpecifications?.length > 0">
        <h2 class="subtitle text-center">Especificaciones Técnicas</h2>
        <div style="width: 100%; margin: auto">
          <mat-card style="padding: 0px" class="mat-elevation-z0">
            <table style="width: 100%">
              <tr class="mat-small" style="color: gray">
                <th style="text-align: left">Texto</th>
                <th style="text-align: right">Valor</th> 
              </tr>
              <mat-divider></mat-divider>
              <tr *ngFor="let specification of technicalSpecifications" class="spec-row">
                <td style="text-align: left">
                  {{ specification.text | electronicComponentParameter:{type: 'technical-specification-text'} }}
                </td>
                <td style="text-align: right">{{ specification.value | electronicComponentParameter:{type: 'technical-specification-value', text: specification.text} }}</td> 
              </tr>
            </table>
          </mat-card>
        </div>
      </div>
      <div fxFlex="50%" fxFlex.lt-sm="100%" *ngIf="component.features?.length > 0">
        <h2 class="subtitle text-center">Características</h2>
        <ul>
          <li *ngFor="let feature of component.features" class="text">
            {{ feature }}
          </li>
        </ul>
      </div>
    </div>
    <div style="background-color: #000808; border-radius: 8px; padding: 16px; margin-top: 16px;" *ngIf="component.images?.length">
      <h2 class="subtitle text-center" style="margin-top: 4px;">Imágenes</h2>
      <div fxLayout="row wrap" fxLayoutAlign="center start" *ngIf="component.images?.length">
        <div fxFlex="0 0 auto" class="container text-center text-ne" *ngFor="let image of component.images">
          <img src="{{ image.url }}" alt="" height="200px" style="border-radius: 16px;">
          <p *ngIf="image.title" style="margin-bottom: 0px; font-weight: bold; color: #CCCCCC">{{ image.title }}</p>
          <p *ngIf="image.source" style="margin-top: 4px; color: #888888"><i>Fuente: {{ image.source }}</i></p>
        </div>
      </div>
    </div>
    <h2 class="subtitle text-center" *ngIf="component.links?.length">Enlaces</h2>
    <div fxLayout="row wrap" fxLayoutAlign="center start">
      <a href="{{ link.url }}" target="_blank" *ngFor="let link of component.links" style="margin: 8px"><button mat-stroked-button color="accent">{{ link.text }}</button></a>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Cerrar</button>
</div>