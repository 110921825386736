<section class="mat-typography">
  <mat-tab-group color="accent" class="user-mat-tab"  animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label style="color: #EEEEEE !important;">
        <mat-icon class="icon-margin">account_circle</mat-icon>
        Usuario
      </ng-template>
      <div class="container">
        <mat-spinner *ngIf="downloading" style="margin:16% auto;" [@fadeAnimation]="'in'"></mat-spinner>
        <div class="text-center" *ngIf="!downloading">
          <br>
          <h1 class="title">{{ userName }}</h1>
          <div>
            <div matRipple class="text-center userPhotoContainer" (click)="changeUserAvatar()">
              <img *ngIf="userPhotoURL" src="{{ userPhotoURL }}" alt="user-photo" class="userPhoto">
              <img *ngIf="!userPhotoURL" src="https://plataforma.nodoelectronico.com/assets/users/robot-1-azul-claro.png" alt="user-photo" width="200px" height="200px">
            </div>
          </div>
          <br>
          <button mat-stroked-button (click)="changeUserAvatar()" color="accent">Cambiar Foto de Perfil</button>
          <br><br>
          <p class="subtitle">{{ userMail }}</p>
          <p class="text">Teléfono: {{ userPhone }}</p>
          <p *ngIf="userEndOfSubscription" class="text">Suscripción activada hasta: {{ userEndOfSubscription | date: 'dd/MMMM/yyyy, h:mm a' }}</p>
          <p *ngIf="!userEndOfSubscription" class="text">Suscripción desactivada</p>
          <br><br>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="icon-margin">receipt</mat-icon>
        Pedidos
      </ng-template>
      <div class="page-container">
        <br>
        <h1 class="title">Últimos 10 Pedidos</h1>
        <mat-list>
          <div *ngFor="let order of userOrders | async">
            <mat-list-item *ngIf="order.source == 'store'">
              <mat-icon matListIcon style="color: #FF8800" class="order-icon">storefront</mat-icon>
              <h3 matLine class="text"><strong>Compra en Tienda</strong></h3>
              <p matLine style="color: #888888;"><strong>Fecha: </strong>{{ order.date | date:'dd/MMMM/yyyy hh:mm a' }}</p>
              <p matLine style="color: #888888;"><strong>Método de pago: </strong>
                <span *ngIf="order.paymentMethod == 'oxxo'">Oxxo</span>
                <span *ngIf="order.paymentMethod == 'paypal'">Paypal</span>
              </p>
              <p matLine style="color: #888888;"><strong>Total:</strong> {{ order.amount | number:'1.2-2' }} MXN</p>
              <button mat-stroked-button color="accent" (click)="showDetails(order)">Ver detalles</button>
            </mat-list-item>
            <mat-list-item *ngIf="order.source == 'subscription'">
              <mat-icon matListIcon style="color: #FFFF00" class="order-icon">local_play</mat-icon>
              <h3 matLine class="text"><strong>
                Suscripción
                <span *ngIf="order.plan == '1-month'"> por 1 mes</span>
                <span *ngIf="order.plan == '3-months'"> por 3 meses</span>
              </strong>
              </h3>
              <p matLine style="color: #888888;"><strong>Fecha: </strong>{{ order.date | date:'dd/MMMM/yyyy hh:mm a' }}</p>
              <p matLine style="color: #888888;"><strong>Método de pago: </strong>
                <span *ngIf="order.paymentMethod == 'coupon'">Código</span>
                <span *ngIf="order.paymentMethod == 'oxxo'">Oxxo</span>
                <span *ngIf="order.paymentMethod == 'paypal'">Paypal</span>
              </p>
              <p matLine *ngIf="order.paymentMethod != 'coupon'" style="color: #888888;"><strong>Total:</strong> {{ order.amount | number:'1.2-2' }} MXN</p>
              <p matLine *ngIf="order.paymentMethod == 'coupon'" style="color: #888888;">
                <strong>Días de acceso: </strong> {{ order.coupon?.days }} días
              </p>
              <button mat-stroked-button color="accent" (click)="showDetails(order)">Ver detalles</button>
            </mat-list-item>
            <mat-divider></mat-divider>
          </div>
        </mat-list>
        <br>
      </div>
    </mat-tab>
  </mat-tab-group>
</section>