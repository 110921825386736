<div fxLayout="row">
  <div fxFlex="30%" fxShow fxHide.lt-md="true" class="side-section dark-theme">
    <p class="text-center" style="margin-bottom: 0px;">
      <img src="../../assets/profile-robot.jpg" alt="" class="profile-robot">
    </p>
    <p class="text text-center" style="margin-top: 4px;"><strong style="color: #888888">@nodo-bot</strong></p>
    <p class="text text-center" style="margin: 8px">
      ¿Tienes alguna duda? Escríbenos, esto es una conversación directa con nuestros profesores
    </p>
  </div>
  <div fxFlex="70%" fxFlex.lt-md="100%">
    <div style="height: calc(100vh - 146px);">
      <div class="messages-container container" fxLayout="column" fxFill fxFlexAlign="center center" #messagesContainer>
        <div style="margin-top: auto;"></div>
        <p class="text-center" *ngIf="!this.gotAllMessages">
          <button mat-stroked-button color="accent" (click)="getMoreMessages()">Ver Mensajes Anteriores</button>
        </p>
        <br>
        <div *ngFor="let message of lastChatMessages">
          <mat-card class="message-received mat-elevation-z0" matTooltip="{{ message.date | date: 'dd/MM/yy hh:mm:ss aa' }}" matTooltipPosition="right" *ngIf="message.type == 'text-received'">
            <span class="text">{{ message.text }}</span>
          </mat-card>
          <mat-card class="message-sent mat-elevation-z0" matTooltip="{{ message.date | date: 'dd/MM/yy hh:mm:ss aa' }}" matTooltipPosition="left" *ngIf="message.type == 'text-sent'">
            <span class="text">{{ message.text }}</span>
          </mat-card>
        </div>
        <div *ngFor="let message of chatMessages">
          <mat-card class="message-received mat-elevation-z0" matTooltip="{{ message.date | date: 'dd/MM/yy hh:mm:ss aa' }}" matTooltipPosition="right" *ngIf="message.type == 'text-received'">
            <span class="text">{{ message.text }}</span>
          </mat-card>
          <mat-card class="message-sent mat-elevation-z0" matTooltip="{{ message.date | date: 'dd/MM/yy hh:mm:ss aa' }}" matTooltipPosition="left" *ngIf="message.type == 'text-sent'">
            <span class="text">{{ message.text }}</span>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="container messages-input-container">
      <mat-form-field class="messages-input" color="accent" appearance="outline">
        <mat-label>Mensaje</mat-label>
        <textarea type="text" autocomplete="off" matInput name="message-text" placeholder="Mensaje" [(ngModel)]="messageText" (keydown.enter)="sendMessage()"></textarea>
      </mat-form-field>
      <button mat-flat-button color="accent" style="margin-left: 8px;" (click)="sendMessage()">Enviar</button>
    </div>
  </div>
</div>