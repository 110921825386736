import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from "@angular/fire/firestore";
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer, SafeResourceUrl, SafeStyle } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Globals } from '../app-globals';

@Component({
  selector: 'app-course-sale-page',
  templateUrl: './course-sale-page.component.html',
  styleUrls: ['./course-sale-page.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(300)
      ]),
      transition(':leave',
        animate(300, style({opacity: 0})))
    ])
  ]
})
export class CourseSalePageComponent implements OnInit {
  public course: any;
  public courseID: string = '';
  public userAuthenticated: boolean = false;
  public courseImage: string = '';
  public courseVideo: string = '';
  public secureCourseVideo: SafeResourceUrl;
  public courseBackgroundStartColor: string = '';
  public courseBackgroundEndColor: string = '';
  public courseTitleColor: string = '';
  public courseDescriptionColor: string = '';
  public secureBackgroundColor: SafeStyle;
  public secureTitleColor: SafeStyle;
  public secureDescriptionColor: SafeStyle;
  public demoLessonPath: string;

  public whatWillLearn: { text: string, icon: string , color: string }[];
  public courseFeatures: { text: string, icon: string , color: string }[];
  public courseFaq: { question: string, answer: string }[];

  public courseContent: { sectionTitle: string, lessons: string[] }[];
  public userLastLessonPath: string;
  public downloading: boolean = true;
  public now: number = 0;

  constructor(
    public sanitizer: DomSanitizer,
    public firestore: AngularFirestore,
    public route: ActivatedRoute,
    public globals: Globals ) { }

  ngOnInit(): void {
    this.now = Date.now();
    setInterval(() => {
      this.now = Date.now();
    }, 60000);

    this.route.paramMap.subscribe((params) => {
      this.downloading = true;
      this.course = null;
      this.demoLessonPath = '';
      this.courseVideo = '';
      this.secureCourseVideo = null;
      this.courseTitleColor = '';
      this.courseDescriptionColor = '';
      this.secureTitleColor = null;
      this.secureDescriptionColor = null;
      this.userLastLessonPath = '';

      this.courseID = params.get('courseID');
      this.firestore.collection('courses', ref => ref.where('routeID','==',this.courseID).limit(1)).valueChanges({ idField: 'id'}).subscribe((data: any)=> {
        let course = data.pop();
        this.course = course;
        this.demoLessonPath = course.demoLessonPath;
        this.courseVideo = course.video;
        this.secureCourseVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.courseVideo + '?transparent=0');
        this.courseTitleColor = course.heroTitleColor;
        this.courseDescriptionColor = course.heroDescriptionColor;
        this.secureTitleColor = this.sanitizer.bypassSecurityTrustStyle(this.courseTitleColor);
        this.secureDescriptionColor = this.sanitizer.bypassSecurityTrustStyle(this.courseDescriptionColor);
        this.globals.userObservable.subscribe(async () => {
          if(this.globals.userUID) {
            let userCourseDoc = await this.firestore.collection('users').doc(this.globals.userUID).collection('courses').doc(this.course.id).ref.get();
            if(userCourseDoc.exists) {
              this.userLastLessonPath = userCourseDoc.data().lastLesson.path;
            }
            this.downloading = false;
          }
          else {
            this.downloading = false;
          }
        })
      });
    });
  }

}
