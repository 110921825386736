<div class="sign-up-background">
  <section class="mat-typography page-container">
    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="center center" style="min-height: calc(100vh - 248px);">
      <div fxFlex="960px" fxFlex.lt-md="100%" [@fadeAnimation]="'in'">
        <br>
        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="center center">
          <div fxFlex="50%" class="text-center" fxLayoutAlign="center center" fxHide.xs="true">
            <img src="../../assets/welcome-robot.png" alt="welcome-robot" style="width: 100%; height: auto; margin-right: 16px;">
          </div>
          <div fxFlex="100%" class="text-center welcome-card-mobile" fxLayoutAlign="center center" fxHide.gt-xs="true">
            <img src="../../assets/welcome-robot.png" alt="welcome-robot" style="height: auto; width: 100%;">
          </div>
          <div fxFlex="50%" fxLayoutAlign="center center">
            <mat-card style="border-radius: 8px;" class="mat-elevation-z24">
              <form>
                <h1 class="title text-center">Registro</h1>
                <mat-form-field class="full-width" appearance="outline" color="accent">
                  <mat-label>Nombre</mat-label>
                  <input matInput required name="user_name" type="text" placeholder="Nombre Apellidos" [(ngModel)]="userName" [disabled]="authenticating" [ngModelOptions]="{standalone: true}">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline" color="accent">
                  <mat-label>Correo Electrónico</mat-label>
                  <input matInput required name="user_mail" type="email" placeholder="usuario@correo.com" [(ngModel)]="userMail" [disabled]="authenticating" [ngModelOptions]="{standalone: true}">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline" color="accent">
                  <mat-label>Teléfono</mat-label>
                  <input matInput required name="user_phone" type="text" placeholder="+521234567890" [(ngModel)]="userPhone" [disabled]="authenticating" [ngModelOptions]="{standalone: true}">
                </mat-form-field>
                <mat-form-field class="full-width"  appearance="outline" color="accent">
                  <mat-label>Contraseña</mat-label>
                  <input matInput required name="user_password" type="password" placeholder="········" [(ngModel)]="userPassword" [disabled]="authenticating" [ngModelOptions]="{standalone: true}" [type]="hide ? 'password' : 'text'">
                  <mat-icon matSuffix style="cursor: pointer" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
                <mat-form-field class="full-width"  appearance="outline" color="accent">
                  <mat-label>Confirma contraseña</mat-label>
                  <input matInput required name="password_confirmation" type="password" placeholder="········" [(ngModel)]="confirmationPassword" [disabled]="authenticating" [ngModelOptions]="{standalone: true}" [type]="hide ? 'password' : 'text'">
                  <mat-icon matSuffix style="cursor: pointer" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
                <p>
                  <button mat-flat-button color="accent" type="submit" [disabled]="authenticating" (click)="registerUser()" class="full-width">Registrarse</button>
                </p>
                <p>
                  <a mat-button routerLink="/aviso-de-privacidad" color="accent"  [disabled]="authenticating" class="full-width">Aviso de Privacidad</a>
                </p>
                <p class="mat-caption text-center">¿Ya tienes cuenta? Entra a la plataforma:</p>
                <div class="text-center">
                  <a routerLink="/acceso" mat-stroked-button color="accent" [disabled]="authenticating" class="full-width">Iniciar Sesión</a>
                </div>
              </form>
            </mat-card>
          </div>
        </div>
        <br><br>
      </div>
    </div>
  </section>  
</div>