<div mat-dialog-content>
  <div *ngIf="order.source == 'store'">
    <h1 class="title text-center" style="margin-top: 0px;"><strong>Compra en Tienda</strong></h1>
    <p style="margin-bottom: 4px;"><strong>Fecha: </strong>{{ order.date | date:'dd/MMMM/yyyy hh:mm a' }}</p>
    <p style="margin: 4px 0px;"><strong>Método de pago: </strong>
      <span *ngIf="order.paymentMethod == 'oxxo'">Oxxo</span>
      <span *ngIf="order.paymentMethod == 'paypal'">Paypal</span>
    </p>
    <p style="margin: 4px 0px;"><strong>Estado: </strong>
      <span *ngIf="order.status == 'pending_payment'">Pago Pendiente</span>
      <span *ngIf="order.status == 'paid'">Pagado</span>
      <span *ngIf="order.status == 'sent'">Enviado</span>
      <span *ngIf="order.status == 'cancelled'">Cancelado</span>
    </p>
    <p style="margin: 4px 0px;"><strong>Total:</strong> {{ order.amount | number:'1.2-2' }} MXN</p>
    <p class="text" style="margin-bottom: 4px;"><strong>Dirección de envío</strong></p>
    <p style="margin: 4px 0px;">
      {{ order.shippingInfo?.name }}<br>
      {{ order.shippingInfo?.addresLine1 }} {{ order.shippingInfo?.addresLine2 }}<br>
      {{ order.shippingInfo?.city }}, {{ order.shippingInfo?.state }}, {{ order.shippingInfo?.country }}<br>
      {{ order.shippingInfo?.zip }}
    </p>
    <h1 class="subtitle text-center">Carrito</h1>
    <table style="width:100%;">
      <thead style="position: sticky; top: 0;">
        <tr>
          <th style="position: sticky; top: 0; width: 50px;"></th>
          <th style="text-align: left; position: sticky; top: 0;"><h3 style="margin-bottom: 4px; margin-top: 0px;">Producto</h3></th>
          <th style="text-align: right; width: 64px; position: sticky; top: 0;"><h3 style="margin-bottom: 4px; margin-top: 0px;" matTooltip="Precio Unitario">P/U</h3></th>
        </tr>
      </thead>
      <tr *ngFor="let product of order.cart; index as i">
        <td> <img src="{{ product.thumbnailImage.url }}" alt="" style="width: 42px; height: 42px; border-radius: 4px;"> </td>
        <td style="text-align: left;"><h3 class="title product-name">{{ product.name }}</h3> <p style="margin: 4px 0px 12px 0px">Cantidad: {{ product.quantity }}</p></td>
        <td style="text-align: right;"><p style="margin: 4px 0px;">${{ product.price | number:'1.2-2' }}</p></td>
      </tr>
    </table>
    <br>
  </div>
  <div *ngIf="order.source == 'subscription'">
    <h1 class="title text-center" style="margin-top: 0px;"><strong>
      Suscripción
      <span *ngIf="order.plan == '1-month'"> por 1 mes</span>
      <span *ngIf="order.plan == '3-months'"> por 3 meses</span>
    </strong></h1>
    <p style="margin-bottom: 0px;"><strong>Fecha: </strong>{{ order.date | date:'dd/MMMM/yyyy hh:mm a' }}</p>
    <p style="margin: 4px 0px;"><strong>Método de pago: </strong>
      <span *ngIf="order.paymentMethod == 'coupon'">Código</span>
      <span *ngIf="order.paymentMethod == 'oxxo'">Oxxo</span>
      <span *ngIf="order.paymentMethod == 'paypal'">Paypal</span>
    </p>
    <p style="margin: 4px 0px;"><strong>Estado: </strong>
      <span *ngIf="order.status == 'pending_payment'">Pago Pendiente</span>
      <span *ngIf="order.status == 'paid'">Pagado</span>
      <span *ngIf="order.status == 'cancelled'">Cancelado</span>
    </p>
    <p *ngIf="order.paymentMethod != 'coupon'" style="margin: 4px 0px;"><strong>Total:</strong> {{ order.amount | number:'1.2-2' }} MXN</p>
    <p *ngIf="order.paymentMethod == 'coupon'" style="margin: 4px 0px;">
      <strong>Días de acceso: </strong> {{ order.coupon?.days }} días
    </p>
  </div>
  <div *ngIf="order.paymentMethod == 'oxxo'">
    <div id="oxxo-order">
      <h1 class="subtitle text-center">Formato de pago en Oxxo</h1>
      <p class="text text-center" style="margin: 0px"><strong>Cantidad: </strong>${{ order.amount }}.00 MXN</p>
      <p class="text text-center" style="margin: 0px"><strong>Fecha de pedido: </strong>{{ order.date | date:'dd/MM/yyyy hh:mm a' }}</p>
      <p class="text text-center" style="margin-top: 0px"><strong>Pague antes del: </strong>{{ (order.paymentData.expires_at)*1000 | date:'dd/MM/yy' }}</p>
      <div class="text-center reference-code">
        <div style="text-align: center;">
          <img src="../../assets/oxxo_pay_Grande.png" alt="Oxxo Pay" style="width: 50%; max-width: 320px;">
        </div>
        <p style="margin-bottom: 4px"><strong>Número de referencia</strong></p>
        <img src="{{order.paymentData.barcode_url}}" alt="Codigo de Barras Oxxo">
        <p style="margin-top: 4px">{{ order.paymentData.reference }}</p>
      </div>
      <br>
      <h3>Instrucciones:</h3>
      <ol>
        <li>Imprime o guarda en tu celular este formato de pago. También lo hemos enviado a tu correo.</li>
        <li>Acude a la tienda Oxxo más cercana.</li>
        <li>Indica en caja que quieres realizar un pago con OXXOPay.</li>
        <li>Muestra el formato de pago o dicta al cajero el número de referencia en esta ficha.</li>
        <li>El cajero podría confirmarte el destino del pago: Emmanuel Rojas o Nodo Electrónico</li>
        <li>Realiza el pago correspondiente con pago en efectivo. Oxxo cobrará una comisión adicional de $13.00 MXN.</li>
        <li>Al confirmar tu pago, el cajero te entregará un comprobante impreso. En el comprobante podrás verificar que se haya realizado correctamente. Conserva este comprobante de pago.</li>
        <li>Minutos después recibirás el correo de confirmación de pago y obtendrás acceso automáticamente a la plataforma</li>
      </ol>
      <br>
      <h3>Cualquier duda o aclaración comunícate con nosotros</h3>
      <p class="text">
        Cel. 221 288 5096 <br>
        Mail: contacto@nodoelectronico.com
      </p>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close="true">Cerrar</button>
  <button mat-flat-button color="accent" printSectionId="oxxo-order" [useExistingCss]="true" ngxPrint style="margin-left: 8px;" *ngIf="order.paymentMethod == 'oxxo'">Imprimir Formato de Pago</button> 
</div>