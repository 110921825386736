<mat-spinner *ngIf="!globals.subscriptionPlans" style="margin:20% auto;"></mat-spinner>
<section class="page-container" *ngIf="globals.subscriptionPlans">
  <a mat-fab color="accent" class="fab-menu" href="https://wa.me/522211204958" target="_blank" matTooltip="Contactar por Whatsapp" matTooltipPosition="left" style="background-color: #25D366 !important;">
    <mat-icon svgIcon="whatsapp"></mat-icon>
  </a>
  <div fxLayout="row wrap" fxLayout.xs="column" class="subscription-toolbox" [@fadeAnimation]="'in'">
    <div fxFlex="100%" *ngIf="globals.subscriptionPlans?.offerAvailable">
      <div class="offer-banner mat-elevation-z16">
        <h1 class="subtitle text-center" style="color: #FFFF88; margin: 0px; font-weight: 700 !important;">
          {{ globals.subscriptionPlans?.offerBannerText }}*
        </h1>
      </div>
    </div>
    <div fxFlex="50%" fxFlex.lt-md="100%" class="text-center">
      <img src="../../assets/subscription-toolbox.png" alt="" width="100%" height="auto" style="max-width: 480px;">
    </div>
    <div fxFlex="50%" fxFlex.lt-md="100%">
      <h1 class="title text-center" style="color: #FFFFFF; line-height: 36px !important;">
        Obtén acceso a todos los Cursos y Aplicaciones
      </h1>
      <p class="text text-center manual-container-text">
        Adquiere tu suscripción con  nuestros distribuidores y canjea el código
      </p>
      <p class="text-center"><a mat-stroked-button color="accent" href="https://nodoelectronico.com/#distribuidores" target="_blank">Ver Distribuidores</a></p>
    </div>
    <div fxFlex="100%">
      <p *ngIf="globals.subscriptionPlans?.offerAvailable" class="text-right text" style="color: #EEEEEE; margin: 0px; font-size: 14px !important;">
        * {{ globals.subscriptionPlans?.validityText }}
      </p>
    </div>
  </div>
  <div class="plan-container" *ngIf="!globals.userUID && globals.subscriptionPlans" [@fadeAnimation]="'in'">
    <h2 class="subtitle text-center" style="margin: 0px;">
      Es necesario iniciar sesión o registrarse para activar la suscripción
    </h2>
    <p class="text-center" style="margin: 4px">
      <button mat-stroked-button color="accent" style="margin-right: 8px;" routerLink="/acceso/suscripcion">Iniciar Sesión</button>
      <button mat-flat-button color="accent" routerLink="/registro/suscripcion">Registrarse</button>
    </p>
  </div>
  <div [@fadeAnimation]="'in'" style="margin-top: 32px;">
    <div fxLayout="row wrap" fxLayout.xs="column">
      <div fxFlex="50%" fxFlex.xs="100%">
        <div class="access-code-manual">
          <img src="../../assets/access-code-manual.png" alt="" width="100%" height="auto" style="margin: auto">
        </div>
      </div>
      <div fxFlex="50%" fxFlex.xs="100%">
        <h2 class="subtitle text-center" style="margin-bottom: 0px;">Introduce el código de acceso</h2>
        <p class="text-center" style="margin-bottom: 0px; margin-top: 0px;">
          <mat-form-field appearance="outline" color="accent" style="width: 154px;">
            <mat-label>Código</mat-label>
            <input matInput style="font-family: monospace; font-weight: 500;" maxlength="12" autocomplete="off" name="access_code" type="text" placeholder="Xk4IfS8FSL" [(ngModel)]="accessCode">
          </mat-form-field>
        </p>
        <p class="text-center" style="margin-top: 0px;">
          <button mat-raised-button style="margin-left: 8px;" color="accent" (click)="checkAccessCode()" [disabled]="accessCode.length < 10">
            <mat-icon class="icon-margin">card_membership</mat-icon>
            Agregar Código
          </button>
        </p>
      </div>
    </div>
  </div>
</section>