<section class="mat-typography">
  <h1 mat-dialog-title class="text-center">Imagen de Usuario</h1>
  <div mat-dialog-content>
    <mat-horizontal-stepper #stepper>
      <mat-step>
        <ng-template matStepLabel>Elige un Robot</ng-template>
        <div fxLayout="row wrap" fxFlexAlign="center center">
          <div fxFlex style="padding: 8px">
            <div matRipple class="text-center userAvatarContainer" (click)="showRobotColors(robot1Colors, stepper)">
              <img src="../../assets/users/robot-1-azul-claro.png" alt="user-photo" class="userAvatar">
            </div>
          </div>
          <div fxFlex style="padding: 8px">
            <div matRipple class="text-center userAvatarContainer" (click)="showRobotColors(robot2Colors, stepper)">
              <img src="../../assets/users/robot-2-azul-claro.png" alt="user-photo" class="userAvatar">
            </div>
          </div>
          <div fxFlex style="padding: 8px">
            <div matRipple class="text-center userAvatarContainer" (click)="showRobotColors(robot3Colors, stepper)">
              <img src="../../assets/users/robot-3-azul.png" alt="user-photo" class="userAvatar">
            </div>
          </div>
          <div fxFlex style="padding: 8px">
            <div matRipple class="text-center userAvatarContainer" (click)="showRobotColors(robot4Colors, stepper)">
              <img src="../../assets/users/robot-4-azul-claro.png" alt="user-photo" class="userAvatar">
            </div>
          </div>
        </div>
        
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Elige un color</ng-template>
        <div fxLayout="row wrap" fxFlexAlign="center center">
          <div fxFlex style="padding: 24px" *ngFor="let robot of robotColors">
            <div matRipple class="text-center userAvatarContainer" (click)="selectRobot(robot)">
              <img src="{{ robot }}" alt="user-photo" class="userAvatar">
            </div>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
  <mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>Cancelar</button>
  </mat-dialog-actions>
</section>