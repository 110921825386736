import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'lesson-test-dialog',
  templateUrl: './lesson-test-dialog.html',
  styleUrls: ['../course-lesson.component.scss']
})
export class LessonTestDialog {
  public selectedAnswer: any;
  public question: string;
  public answers: any[];

  constructor(
    public dialogRef: MatDialogRef<LessonTestDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar) {
      this.question = data.question;
      this.answers = data.answers;
  }

  evaluateTest() {
    if(this.selectedAnswer.isCorrect) {
      this.dialogRef.close(true);
    }
    else {
      this.snackBar.open('Lo sentimos, respuesta incorrecta 😢', 'OK', { duration: 3000, panelClass: 'error-snack-bar', horizontalPosition: 'center', verticalPosition: 'top' });
    }
  }
}