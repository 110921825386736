<div mat-dialog-content style="max-height: initial; margin: 0px; padding: 0px;">
  <div class="lesson-cover" [style.background-image]="sanitizer.bypassSecurityTrustStyle('url('+ lesson.coverImage +')')">
    <h1 class="title" mat-dialog-title style="margin-top: 72px; margin-bottom: 4px; color: white">{{ lesson.title }}</h1>
    <p><span *ngIf="lesson.completed" class="completed-flag">Completado</span></p>
    <p class="text" style="margin-bottom: 4px;">{{ lesson.abstract }}</p>
    <mat-list dense>
      <mat-list-item class="text" style="font-size: 16px !important; margin-bottom: 0px; height: 32px;"><mat-icon style="margin-right: 8px;">schedule</mat-icon> Duración estimada: {{ lesson.duration }} minutos</mat-list-item>
      <mat-list-item class="text" style="font-size: 16px !important; margin-bottom: 0px; height: 32px;"><mat-icon style="margin-right: 8px;">poll</mat-icon> Nivel: {{ lesson.level }}</mat-list-item>
    </mat-list>
    <p class="text-right" style="margin-bottom: 4px;">
      <button mat-stroked-button color="accent" mat-dialog-close style="margin-right: 16px; width: 84px;">Cerrar</button>
      <button mat-flat-button color="accent" mat-dialog-close="true" style="width: 84px;">Iniciar</button>
    </p>
  </div>
</div>