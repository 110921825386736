<mat-spinner *ngIf="downloading" style="margin:20% auto;"></mat-spinner>
<section [@fadeAnimation]="'in'" *ngIf="!downloading">
  <br>
  <div fxLayout="row wrap" class="app-container">
    <div fxFlex="100%">
      <div fxLayout="row wrap">
        <div fxFlex="100px">
          <img src="{{ appInfo.icon }}" alt="" width="100%" height="auto">
        </div>
        <div fxFlex style="padding: 0px 16px;">
          <h1 class="title" style="margin: 0px;">{{ appInfo?.name }}</h1>
          <h2 class="app-description" style="margin: 0px;">{{ appInfo.description }}</h2>
          <p class="app-version" style="margin: 0px;">(v{{ appInfo.lastVersion }})</p>
          <p>
            <a href="{{ appInfo.iOSURL }}" target="_blank" mat-stroked-button color="accent" style="margin-right: 8px; margin-bottom: 8px;" *ngIf="appInfo.iOSURL"><mat-icon svgIcon="app-store" style="margin-right: 8px;"></mat-icon>Apple App Store</a>
            <a href="{{ appInfo.androidURL }}" target="_blank" mat-stroked-button color="accent" style="margin-right: 8px; margin-bottom: 8px;" *ngIf="appInfo.androidURL"><mat-icon svgIcon="play-store" style="margin-right: 8px;"></mat-icon>Google Play Store</a>
            <a href="{{ appInfo.macOSURL }}" download mat-stroked-button color="accent" style="margin-right: 8px; margin-bottom: 8px;" *ngIf="appInfo.macOSURL" (click)="logDownloadEvent('macOS')"><mat-icon svgIcon="apple" style="margin-right: 8px;"></mat-icon>Mac OS X x64</a>
            <a href="{{ appInfo.windowsURL }}" target="_blank" mat-stroked-button color="accent" style="margin-right: 8px; margin-bottom: 8px;" *ngIf="appInfo.windowsURL" (click)="logDownloadEvent('Windows')"><mat-icon svgIcon="windows" style="margin-right: 8px;"></mat-icon>Windows x64</a>
            <a href="{{ appInfo.webURL }}" target="_blank" mat-stroked-button color="accent" style="margin-right: 8px; margin-bottom: 8px;" *ngIf="appInfo.webURL" (click)="logDownloadEvent('Web')"><mat-icon style="margin-right: 8px;">web</mat-icon>Web</a>
          </p>
        </div>
      </div>
      <div fxLayout="row wrap">
        <p>
          <img src="{{ appInfo.bannerURL }}" alt="banner" class="mat-elevation-z8 main-banner">
        </p>
      </div>
      <div *ngFor="let block of appInfo?.content">
        <h2 *ngIf="block.type == 'subtitle'" class="subtitle">{{ block.text }}</h2>
        <p *ngIf="block.type == 'plainText'" class="text">{{ block.text }}</p>
        <div *ngIf="block.type == 'HTMLText'" [innerHTML]="block.text" class="text"></div>
        <div class="text-center"><a mat-stroked-button *ngIf="block.type == 'link'" href="{{ block.url }}" target="_blank">{{ block.text }}</a></div>
        <p class="text-center"><a mat-stroked-button color="accent" *ngIf="block.type == 'downloadLink'" href="{{ block.url }}" download>
          <mat-icon style="margin-right: 8px;">get_app</mat-icon>
          {{ block.text }}
        </a></p>
        <p *ngIf="block.type == 'image'" class="text-center">
          <img src="{{ block.url }}" alt="{{ block.fileName }}" style="border-radius: 8px;" class="mat-elevation-z8" [ngStyle]="{'width': block.width}">
        </p>
        <mat-nav-list *ngIf="block.type == 'bom'">
          <a mat-list-item *ngFor="let component of block.components" (click)="showComponentInformation(component)">
            <img matListAvatar src="{{ component.thumbnail.url }}" alt="...">
            <p matLine class="text">
              {{ component.quantity }}x {{ component.name }}
            </p>
          </a>
        </mat-nav-list>
        <div *ngIf="block.type == 'code'">
          <iframe [src]="block.secureCode" style="width:100%; min-height:200px; border:0; overflow:hidden;" sandbox="allow-scripts allow-same-origin" [ngStyle]="{'min-height': block.height}"></iframe>
        </div>
        <div *ngIf="block.type == 'latex'">
          <ng-katex [equation]="block.code" [options]="latexOptions"></ng-katex>
        </div>
      </div>
      <div fxLayout="row wrap" style="margin-top: 32px;">
        <div fxFlex>
          <h2 class="subtitle text-center" style="margin: 0px;">Descarga de Aplicación</h2>
          <p class="text-center">
            <a href="{{ appInfo.iOSURL }}" target="_blank" mat-stroked-button color="accent" style="margin-right: 8px; margin-bottom: 8px;" *ngIf="appInfo.iOSURL"><mat-icon svgIcon="app-store" style="margin-right: 8px;"></mat-icon>Apple App Store</a>
            <a href="{{ appInfo.androidURL }}" target="_blank" mat-stroked-button color="accent" style="margin-right: 8px; margin-bottom: 8px;" *ngIf="appInfo.androidURL"><mat-icon svgIcon="play-store" style="margin-right: 8px;"></mat-icon>Google Play Store</a>
            <a href="{{ appInfo.macOSURL }}" download mat-stroked-button color="accent" style="margin-right: 8px; margin-bottom: 8px;" *ngIf="appInfo.macOSURL" (click)="logDownloadEvent('macOS')"><mat-icon svgIcon="apple" style="margin-right: 8px;"></mat-icon>Mac OS X x64</a>
            <a href="{{ appInfo.windowsURL }}" target="_blank" mat-stroked-button color="accent" style="margin-right: 8px; margin-bottom: 8px;" *ngIf="appInfo.windowsURL" (click)="logDownloadEvent('Windows')"><mat-icon svgIcon="windows" style="margin-right: 8px;"></mat-icon>Windows x64</a>
            <a href="{{ appInfo.webURL }}" target="_blank" mat-stroked-button color="accent" style="margin-right: 8px; margin-bottom: 8px;" *ngIf="appInfo.webURL" (click)="logDownloadEvent('Web')"><mat-icon style="margin-right: 8px;">web</mat-icon>Web</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>