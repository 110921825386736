import { Component, OnDestroy, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Globals } from "../app-globals";
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { DomSanitizer } from '@angular/platform-browser';

import MeiliSearch from 'meilisearch'

const client = new MeiliSearch({
  host: 'https://search.nodoelectronico.com',
  apiKey: 'eff7f9fa74b58c365fae4d961b1880ace4ef8a2cdb79943afd93a2d257237d98',
});
const searchIndex = client.getIndex('content');

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(500)
      ]),
      transition(':leave',
        animate(0, style({opacity: 0})))
    ])
  ]
})
export class DashboardComponent implements OnInit, OnDestroy {
  public downloading: boolean = true;
  public downloadingContent: boolean = true;
  public gotAllArticles: boolean = false;
  public articlesBatchSize: number = 8;
  public infographicsBatchSize: number = 2;
  public contentBlocks: any[] = [];
  public pageIndex: number = 0;
  public userCourses: any[] = [];
  public userObservable: any;
  public textToSearch: string = '';
  public searchResults: any[] = [];
  public showSearchResults: boolean = false;
  public events: any[] = [];

  constructor(public dialog: MatDialog, public globals: Globals, public router: Router, public snackBar: MatSnackBar, public firestore: AngularFirestore, public sanitizer: DomSanitizer ) { }

  ngOnInit() {
    this.userObservable = this.globals.userObservable.subscribe(async() => {
      this.downloading = false;
      let now = Date.now();
      if(this.globals.userSubscription < now || this.globals.userSubscription == null) {
        this.snackBar.open('Lo sentimos, tu suscripción a terminado', 'OK', { duration: 10000, panelClass: 'error-snack-bar', horizontalPosition: 'center', verticalPosition: 'top' });
        this.router.navigateByUrl('suscripcion');
      }
      else {
        // Events
        this.events = [];
        this.firestore.collection('events', ref => ref.where('available','==',true).orderBy('date','desc')).valueChanges({ idField: 'id' }).subscribe((eventsData) => {
          this.events = eventsData;
          console.log(this.events);
        });

        // User Courses
        this.userCourses = [];
        console.log(this.globals.userCourses);
        if(this.globals.userCourses) {
          if(this.globals.userCourses.length > 0) {
            this.globals.userCourses.forEach((courseData) => {
              let selectedCourse;
              this.globals.courses.forEach((platformCourse) => {
                if(platformCourse.path == courseData.path) {
                  selectedCourse = platformCourse;
                  let lessonsCompleted = 0;
                  selectedCourse.lessons.forEach(lesson => {
                    courseData.lessonsCompleted.forEach((lessonCompleted) => {
                      if(lessonCompleted.path == lesson) {
                        lessonsCompleted++;
                      }
                    });
                  });
                  selectedCourse.userProgress = (lessonsCompleted/selectedCourse.lessons.length)*100;
                  this.userCourses.push(selectedCourse);
                }
              })
            });
          }
        }

        this.downloading = false;
        this.downloadingContent = false;
      }
    });
  }

  ngOnDestroy() {
    this.userObservable.unsubscribe();
  }

  async search() {
    if(this.textToSearch.length > 2) {
      let searchResponse = await searchIndex.search(this.textToSearch);
      this.searchResults = searchResponse.hits;
      this.showSearchResults = true;
      console.log(this.searchResults);
    }
    else {
      if(this.textToSearch.length == 0) {
        this.showSearchResults = false;
        this.searchResults = [];
      }
    }
  }

  clearSearch() {
    this.searchResults = [];
    this.showSearchResults = false;
    this.textToSearch = '';
  }

}
