import { Component, OnDestroy, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Globals } from '../app-globals';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IPayPalConfig, ICreateOrderRequest  } from 'ngx-paypal';
import { environment } from '../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(300)
      ]),
      transition(':leave',
        animate(300, style({opacity: 0})))
    ])
  ]
})
export class PaymentComponent implements OnInit, OnDestroy {
  public total: number = 0;
  public addressFormGroup: FormGroup;
  public payPalConfig?: IPayPalConfig;
  public paymentMethod: string = null;
  public processingPaymentSnackBar: MatSnackBarRef<any>;
  public shippingName: string = '';
  public addressLine1: string = '';
  public addressLine2: string = '';
  public city: string = '';
  public state: string = '';
  public zipCode: string = '';
  public processingOrder: boolean = true;
  public oxxoOrder: any;
  public updatingCartInformation: boolean = true;
  public orderCompleted: boolean = false;

  constructor(
    public globals: Globals,
    public formBuilder: FormBuilder,
    public http: HttpClient,
    public firestore: AngularFirestore,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public router: Router ) { }

  ngOnInit(): void {
    this.total = 0;
    this.globals.cart.forEach(async (product, index) => {
      // Get the updated price
      let productDoc = await this.firestore.collection('products').doc(product.id).ref.get();
      let productData = productDoc.data();
      if(!productData.available) {
        this.globals.cart.splice(index,1);
        this.processingPaymentSnackBar = this.snackBar.open('El producto ' + product.name + ' ya no está disponible', 'OK', {
          panelClass: 'dark-snack-bar',
          horizontalPosition: 'right',
          verticalPosition: 'top'
        });
        if(this.globals.cart.length == 0) {
          this.router.navigateByUrl('/tienda');
        }
      }
      else {
        if(product.price != productData.price) {
          product.price = productData.price;
          this.processingPaymentSnackBar = this.snackBar.open('Se actualizó el precio de ' + product.name, 'OK', {
            panelClass: 'dark-snack-bar',
            horizontalPosition: 'right',
            verticalPosition: 'top'
          });
        }
        this.total = this.total + (product.price * product.quantity);
      }
    });
    this.updatingCartInformation = false;
    if(this.globals.cart.length == 0) {
      this.router.navigateByUrl('/tienda');
    }
    this.addressFormGroup = this.formBuilder.group({
      nameCtrl: ['', Validators.required],
      addressLine1Ctrl: ['', Validators.required],
      addressLine2Ctrl: ['', Validators.required],
      cityCtrl: ['', Validators.required],
      stateCtrl: ['', Validators.required],
      zipCtrl: ['', Validators.required]
    });
    this.paypalSetup();
  }

  ngOnDestroy() {
    if(this.orderCompleted)
      this.globals.cart = [];
  }

  async createOxxoOrder() {
    this.processingOrder = true;
    this.paymentMethod = 'oxxo';

    let now: any = await this.http.get('https://us-central1-nodo-electronico.cloudfunctions.net/getTime').toPromise();
    let order: any = {
      userUID: this.globals.userUID,
      userName: this.globals.userName,
      userEmail: this.globals.userMail,
      userPhone: this.globals.userPhone,
      shippingInfo: {
        name: this.shippingName,
        addresLine1: this.addressLine1,
        addresLine2: this.addressLine2,
        city: this.city,
        state: this.state,
        country: 'México',
        zip: this.zipCode,
      },
      orderID: '',
      cart: this.globals.cart,
      source: 'store',
      status: '',
      amount: this.total,
      paymentMethod: 'oxxo',
      date: now.time
    }

    let orderDoc = await this.firestore.collection('orders').add(order);
    this.http.get('https://us-central1-nodo-electronico.cloudfunctions.net/createStoreOxxoPayment?uid=' + this.globals.userUID + '&orderID=' + orderDoc.id).subscribe(async(result: any) => {
      order.paymentData = {
        barcode_url: result.oxxoOrder.payment_method.barcode_url,
        expires_at: result.oxxoOrder.payment_method.expires_at,
        reference: result.oxxoOrder.payment_method.reference,
      }
      order.orderID = result.oxxoOrder.order_id,
      order.status = result.oxxoOrder.status,
      this.oxxoOrder = order;
      console.log(order);
      await this.firestore.collection('orders').doc(orderDoc.id).update({
        paymentData: {
          barcode_url: result.oxxoOrder.payment_method.barcode_url,
          expires_at: result.oxxoOrder.payment_method.expires_at,
          reference: result.oxxoOrder.payment_method.reference
        },
        orderID: result.oxxoOrder.order_id,
        status: result.oxxoOrder.status
      });
      this.processingOrder = false;
      this.orderCompleted = true;
    });
  }

  paypalSetup() {
    let paypalCart: any[] = []
    console.log(this.globals.cart);
    this.globals.cart.forEach((product) => {
      paypalCart.push({
        name: product.name,
        quantity: product.quantity,
        category: 'PHYSICAL_GOODS',
        unit_amount: {
          currency_code: 'MXN',
          value: product.price.toString()
        }
      });
    });
    console.log(paypalCart);

    this.payPalConfig = {
      currency: 'MXN',
      clientId: environment.paypal.clientID,
      style: {
        label: 'paypal',
        color: 'blue',
        tagline: false,

      },
      createOrderOnClient: (data) => <ICreateOrderRequest> {
        intent: 'CAPTURE',
        application_context: {
          brand_name: 'Nodo Electrónico',
          shipping_preference: 'SET_PROVIDED_ADDRESS',
        },
        purchase_units: [{
          amount: {
            currency_code: 'MXN',
            value: this.total.toString(),
            breakdown: {
              item_total: {
                currency_code: 'MXN',
                value: this.total.toString()
              }
            }
          },
          items: paypalCart,
          shipping: {
            name: {
              full_name: 'Emmanuel'
            },
            address: {
              country_code: 'MX',
              address_line_1: this.addressLine1,
              address_line_2: this.addressLine2,
              admin_area_1: this.city,
              admin_area_2: this.state,
              postal_code: this.zipCode
            }
          }
        }]
      },
      advanced: {
        extraQueryParams: [{
          name: 'locale',
          value: 'es_MX'
        }]
      },
      onApprove: (data, actions) => { },
      onClientAuthorization: async (data) => {
        let now: any = await this.http.get('https://us-central1-nodo-electronico.cloudfunctions.net/getTime').toPromise();
        let order = {
          userUID: this.globals.userUID,
          userName: this.globals.userName,
          userEmail: this.globals.userMail,
          userPhone: this.globals.userPhone,
          orderID: data.id,
          cart: this.globals.cart,
          shippingInfo: {
            name: this.shippingName,
            addresLine1: this.addressLine1,
            addresLine2: this.addressLine2,
            city: this.city,
            state: this.state,
            country: 'México',
            zip: this.zipCode,
          },
          source: 'store',
          status: 'paid',
          amount: this.total,
          paymentMethod: 'paypal',
          date: now.time
        }
        this.firestore.collection('orders').add(order).then( async(orderDoc) => {
          await this.http.get('https://us-central1-nodo-electronico.cloudfunctions.net/confirmPayment?orderID=' + orderDoc.id).toPromise();

          this.router.navigateByUrl('/tienda');
          this.processingPaymentSnackBar.dismiss();
          this.globals.cart = [];
          this.orderCompleted = true;
          this.snackBar.open('¡Gracias por tu compra! te enviaremos tu pedido en 2 o 3 días hábiles', 'OK', {
            duration: 10000,
            panelClass: 'success-snack-bar',
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
        });
      },
      onCancel: (data, actions) => {
        this.processingPaymentSnackBar.dismiss();
        this.snackBar.open('Se canceló tu pago en Paypal', 'OK', { duration: 5000, panelClass: 'dark-snack-bar', horizontalPosition: 'center', verticalPosition: 'top' });
      },
      onError: (err) => {
        this.processingPaymentSnackBar.dismiss();
        this.snackBar.open('Lo sentimos, hubo un error, intente nuevamente', 'OK', { duration: 5000, panelClass: 'error-snack-bar', horizontalPosition: 'center', verticalPosition: 'top' });
      },
      onClick: () => {
        this.processingPaymentSnackBar = this.snackBar.open('Se está procesando tu pago...', 'OK', {
          panelClass: 'dark-snack-bar',
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      },
    };
  }

}
