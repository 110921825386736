<div mat-dialog-content>
  <mat-spinner *ngIf="processingOrder" style="margin:16px auto;"></mat-spinner>
  <p *ngIf="processingOrder" class="text text-center">Generando Formato de Pago...</p>
  <div *ngIf="!processingOrder">
    <div id="oxxo-order">
      <h1 class="title text-center">Formato de pago en Oxxo</h1>
      <p class="text text-center" style="margin: 0px"><strong>Cantidad: </strong>${{ order.amount }}.00 MXN</p>
      <p class="text text-center" style="margin: 0px"><strong>Fecha de pedido: </strong>{{ order.date | date:'dd/MM/yy h:mm a' }}</p>
      <p class="text text-center" style="margin-top: 0px"><strong>Pague antes del: </strong>{{ (order.paymentData.expires_at)*1000 | date:'dd/MM/yy' }}</p>
      <div class="text-center reference-code">
        <div style="text-align: center;">
          <img src="../../assets/oxxo_pay_Grande.png" alt="Oxxo Pay" style="width: 50%; max-width: 320px;">
        </div>
        <p style="margin-bottom: 4px"><strong>Número de referencia</strong></p>
        <img src="{{order.paymentData.barcode_url}}" alt="Codigo de Barras Oxxo">
        <p style="margin-top: 4px">{{ order.paymentData.reference }}</p>
      </div>
      <br>
      <h3>Instrucciones:</h3>
      <ol>
        <li>Imprime o guarda en tu celular este formato de pago. También lo hemos enviado a tu correo.</li>
        <li>Acude a la tienda Oxxo más cercana.</li>
        <li>Indica en caja que quieres realizar un pago con OXXOPay.</li>
        <li>Muestra el formato de pago o dicta al cajero el número de referencia en esta ficha.</li>
        <li>El cajero podría confirmarte el destino del pago: Emmanuel Rojas o Nodo Electrónico</li>
        <li>Realiza el pago correspondiente con pago en efectivo. Oxxo cobrará una comisión adicional de $13.00 MXN.</li>
        <li>Al confirmar tu pago, el cajero te entregará un comprobante impreso. En el comprobante podrás verificar que se haya realizado correctamente. Conserva este comprobante de pago.</li>
        <li>Minutos después recibirás el correo de confirmación de pago y obtendrás acceso automáticamente a la plataforma</li>
      </ol>
      <br>
      <h3>Cualquier duda o aclaración comunícate con nosotros</h3>
      <p class="text">
        Cel. 221 288 5096 <br>
        Mail: contacto@nodoelectronico.com
      </p>
    </div>
    <div class="text-center">
      
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button color="accent" mat-dialog-close="true">Cerrar</button>
  <button mat-flat-button color="accent" printSectionId="oxxo-order" [useExistingCss]="true" ngxPrint style="margin-left: 8px;">Imprimir</button> 
</div>