import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'sponsor-ad-dialog',
  templateUrl: './sponsor-ad-dialog.html'
})
export class SponsorAdDialog {
  public sponsor: any;

  constructor(
    public dialogRef: MatDialogRef<SponsorAdDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if(data != null) {
        this.sponsor = data;
      }
  }
}