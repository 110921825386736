import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ActivatedRoute } from "@angular/router";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-app-details',
  templateUrl: './app-details.component.html',
  styleUrls: ['./app-details.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(300)
      ]),
      transition(':leave',
        animate(300, style({opacity: 0})))
    ])
  ]
})
export class AppDetailsComponent implements OnInit {
  public appID: string;
  public downloading: boolean = true;
  public appInfo: any;

  constructor( public firestore: AngularFirestore, public route: ActivatedRoute, public analytics: AngularFireAnalytics ) { }

  ngOnInit() {
    this.downloading = true;
    this.route.paramMap.subscribe( async(params) => {
      this.appID = params.get('appID');
      let appData = await this.firestore.collection('apps', ref => ref.where('routeID','==',this.appID)).snapshotChanges().pipe(take(1)).toPromise();
      this.appInfo = appData[0].payload.doc.data();
      this.downloading = false;
    });
  }

  logDownloadEvent(platform: string) {
    this.analytics.logEvent('app-download', {
      app: this.appID,
      version: this.appInfo.lastVersion,
      platform: platform
    })
  }

}
