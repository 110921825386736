import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { IPayPalConfig, ICreateOrderRequest  } from 'ngx-paypal';
import { Globals } from '../app-globals';
import { environment } from '../../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { OxxoPayDialog } from './oxxo-pay-dialog/oxxo-pay-dialog';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessCodeDialog } from './access-code-dialog/access-code-dialog';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(300)
      ]),
      transition(':leave',
        animate(0, style({opacity: 0})))
    ])
  ]
})
export class SubscribeComponent implements OnInit {
  public payDirectly: boolean = true;
  public payPalConfig?: IPayPalConfig;
  public acceptTermsAndConditions: boolean = true;
  public planSelected: string = '';
  public planPrice: number = 0;
  public accessCode: string = '';
  public coupon: any;
  public processingPaymentSnackBar: MatSnackBarRef<any>;

  constructor(
    public globals: Globals,
    public firestore: AngularFirestore,
    public snackBar: MatSnackBar,
    public route: ActivatedRoute,
    public router: Router,
    public http: HttpClient,
    public dialog: MatDialog) { }

  async ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if(params.has('accessCode')) {
        this.accessCode = params.get('accessCode');
        if(this.accessCode != '') {
          this.globals.userObservable.subscribe(() => {
            if(this.globals.userUID) {
              this.planSelected = 'access-code';
              this.checkAccessCode();
            }
            else {
              this.snackBar.open('Es necesario iniciar sesión o registrarse para cambiar tu código, se redirigirá automáticamente', 'OK', { duration: 5000, panelClass: 'dark-snack-bar', horizontalPosition: 'center', verticalPosition: 'top' }).afterDismissed().subscribe(() => {
                localStorage.setItem('subscriptionAccessCode', this.accessCode);
                this.router.navigateByUrl('/acceso/suscripcion')
              });
            }
          });
        }
      }
    });

    let subscriptionAccessCode = localStorage.getItem('subscriptionAccessCode');
    if(subscriptionAccessCode) {
      this.accessCode = subscriptionAccessCode;
      if(this.accessCode != '') {
        localStorage.removeItem('subscriptionAccessCode');
        this.globals.userObservable.subscribe(() => {
          if(this.globals.userUID) {
            this.planSelected = 'access-code';
            this.checkAccessCode();
          }
          else {
            this.snackBar.open('Es necesario iniciar sesión o registrarse para cambiar tu código, se redirigirá automáticamente', 'OK', { duration: 5000, panelClass: 'dark-snack-bar', horizontalPosition: 'center', verticalPosition: 'top' }).afterDismissed().subscribe(() => {
              this.router.navigateByUrl('/acceso/suscripcion')
            });
          }
        });
      }
    }

    this.initConfig();
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: 'MXN',
      clientId: environment.paypal.clientID,
      style: {
        label: 'paypal',
        color: 'blue'
      },
      createOrderOnClient: (data) => < ICreateOrderRequest > {
        intent: 'CAPTURE',
        application_context: {
          brand_name: 'Nodo Electrónico',
          shipping_preference: 'NO_SHIPPING'
        },
        purchase_units: [{
          amount: {
            currency_code: 'MXN',
            value: this.planPrice.toString(),
            breakdown: {
              item_total: {
                currency_code: 'MXN',
                value: this.planPrice.toString()
              }
            }
          },
          items: [{
            name: 'Suscripción a la Plataforma de Nodo Electrónico',
            quantity: '1',
            category: 'DIGITAL_GOODS',
            unit_amount: {
                currency_code: 'MXN',
                value: this.planPrice.toString(),
            },
          }]
        }]
      },
      advanced: {
        extraQueryParams: [{
          name: 'locale',
          value: 'es_MX'
        }]
      },
      onApprove: (data, actions) => { },
      onClientAuthorization: async (data) => {
        this.processingPaymentSnackBar.dismiss();
        let serverTime: any = await this.http.get('https://us-central1-nodo-electronico.cloudfunctions.net/getTime').toPromise();
        let now = serverTime.time;
        let order = {
          userUID: this.globals.userUID,
          userName: this.globals.userName,
          userEmail: this.globals.userMail,
          userPhone: this.globals.userPhone,
          orderID: data.id,
          source: 'subscription',
          status: 'paid',
          amount: this.planPrice,
          paymentMethod: 'paypal',
          date: now
        }
        let subscriptionDays = 1;
        if(this.planSelected == '1-month') {
          subscriptionDays = this.globals.subscriptionPlans.oneMonth.days;
        }
        if(this.planSelected == '3-months') {
          subscriptionDays = this.globals.subscriptionPlans.threeMonths.days;
        }
        if(this.globals.userSubscription < now || this.globals.userSubscription == null) {
          this.globals.userSubscription = now + 1000*60*60*24*subscriptionDays;
        }
        else {
          this.globals.userSubscription = this.globals.userSubscription + 1000*60*60*24*subscriptionDays;
        }
        this.firestore.collection('users').doc(this.globals.userUID).update({ endOfSubscription: this.globals.userSubscription, sponsor: null }).then((result) => {
          console.log(result);
          this.firestore.collection('orders').add(order).then(() => {
            this.router.navigateByUrl('/inicio');
            this.snackBar.open('¡Has realizado tu pago exitosamente! Disfruta de tu acceso a la plataforma', 'OK', {
              duration: 10000,
              panelClass: 'success-snack-bar',
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
          });
        })
      },
      onCancel: (data, actions) => {
        this.processingPaymentSnackBar.dismiss();
        this.snackBar.open('Se canceló tu pago en Paypal', 'OK', { duration: 5000, panelClass: 'dark-snack-bar', horizontalPosition: 'center', verticalPosition: 'top' });
      },
      onError: (err) => {
        this.processingPaymentSnackBar.dismiss();
        this.snackBar.open('Lo sentimos, hubo un error, intente nuevamente', 'OK', { duration: 5000, panelClass: 'error-snack-bar', horizontalPosition: 'center', verticalPosition: 'top' });
      },
      onClick: () => {
        this.processingPaymentSnackBar = this.snackBar.open('Se está procesando tu pago...', 'OK', {
          panelClass: 'dark-snack-bar',
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      },
    };
  }

  createOxxoOrder() {
    this.dialog.open(OxxoPayDialog, {
      width: '600px',
      autoFocus: false,
      data: {
        userUID: this.globals.userUID,
        plan: this.planSelected
      }
    }).afterClosed().subscribe((result) => {
      if(result) {
        this.snackBar.open('En cuánto realices el pago automáticamente se activará tu cuenta', 'OK', {
          duration: 10000,
          panelClass: 'dark-snack-bar',
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
    });
  }

  async checkAccessCode() {
    if(this.accessCode == '') {
      this.snackBar.open('Error, introduce el código de tu cupón', 'OK', {
        duration: 5000,
        panelClass: 'error-snack-bar',
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return;
    }
    console.log(this.accessCode);
    let couponsData = await this.firestore.collection('coupons', ref => ref.where('code','==',this.accessCode)).valueChanges({ idField: 'id' }).pipe(take(1)).toPromise();
    console.log(couponsData);
    if(couponsData.length < 1) {
      localStorage.removeItem('subscriptionAccessCode');
      this.snackBar.open('Error, el código no existe', 'OK', {
        duration: 5000,
        panelClass: 'error-snack-bar',
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return;
    }
    this.coupon = couponsData.pop();
    console.log(this.coupon);
    if(this.coupon.status == 'active') {
      this.dialog.open(AccessCodeDialog, {
        width: '600px',
        data: this.coupon,
        autoFocus: false
      }).afterClosed().subscribe(async (result) => {
        if(result) {
          this.firestore.collection('coupons').doc(this.coupon.id).update({status: 'used'});
          localStorage.removeItem('subscriptionAccessCode');
          let serverTime: any = await this.http.get('https://us-central1-nodo-electronico.cloudfunctions.net/getTime').toPromise();
          let today = serverTime.time;
          if(this.globals.userSubscription < today || this.globals.userSubscription == null) {
            this.globals.userSubscription = today + 1000*60*60*24*this.coupon.days;
          }
          else {
            this.globals.userSubscription = this.globals.userSubscription + 1000*60*60*24*this.coupon.days;
          }
          if(this.coupon.sponsor) {
            this.firestore.collection('users').doc(this.globals.userUID).update({
              sponsor: this.coupon.sponsor,
              endOfSubscription: this.globals.userSubscription
            });
          }
          else {
            this.firestore.collection('users').doc(this.globals.userUID).update({
              sponsor: null,
              endOfSubscription: this.globals.userSubscription
            });
          }
          let order = {
            userUID: this.globals.userUID,
            userName: this.globals.userName,
            userEmail: this.globals.userMail,
            userPhone: this.globals.userPhone,
            coupon: this.coupon,
            status: 'paid',
            source: 'subscription',
            amount: 0,
            paymentMethod: 'coupon',
            date: today
          }
          this.firestore.collection('orders').add(order);
          this.router.navigateByUrl('/inicio');
          this.snackBar.open('¡Has canjeado tu código exitosamente! Disfruta de tu acceso a la plataforma', 'OK', {
            duration: 10000,
            panelClass: 'success-snack-bar',
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
        }
      });
    }
    if(this.coupon.status == 'inactive') {
      localStorage.removeItem('subscriptionAccessCode');
      this.snackBar.open('Lo lamentamos, tu código no está activado, pida a su vendedor que active este código', 'OK', {
        duration: 5000,
        panelClass: 'error-snack-bar',
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    }
    if(this.coupon.status == 'used') {
      localStorage.removeItem('subscriptionAccessCode');
      this.snackBar.open('Error, este código ya fue usado', 'OK', {
        duration: 5000,
        panelClass: 'error-snack-bar',
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    }
  }
}
