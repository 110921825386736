import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, withLatestFrom, filter } from 'rxjs/operators';
import { Globals } from "./app-globals";
import { SponsorAdDialog } from './sponsor-ad-dialog/sponsor-ad-dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public userAuthenticated: boolean = true;
  public pageText: string = '';
  public darkThemeEnabled: boolean = true;
  public userName: string = '';
  public userMail: string = '';
  public userPhotoURL: string = '';
  public userDoc: any;
  public user: Observable<any>;
  public userUID: string = '';
  public userConfig: any;
  public userSponsor: any;
  public isHandset: boolean;
  public userSessionChecked: boolean = false;
  public siteConfigDownloaded: boolean = false;
  public siteContentDownloaded: boolean = false;

  @ViewChild('drawer') drawer: MatSidenav;
  @ViewChild('appContent') appContent : MatSidenavContent;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public router: Router,
    public fireauth: AngularFireAuth,
    public firestore: AngularFirestore,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public matIconRegistry: MatIconRegistry,
    public domSanitizer: DomSanitizer,
    public globals: Globals,
    public http: HttpClient,
    private overlayContainer: OverlayContainer
  ) {

    this.globals.cart = [];

    this.isHandset$.subscribe((data) => {
      this.isHandset = data;
    })

    this.matIconRegistry.addSvgIcon('whatsapp', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/whatsapp-brands.svg"));
    this.matIconRegistry.addSvgIcon('facebook', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/facebook-brands.svg"));
    this.matIconRegistry.addSvgIcon('youtube', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/youtube-brands.svg"));
    this.matIconRegistry.addSvgIcon('twitter', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/twitter-brands.svg"));
    this.matIconRegistry.addSvgIcon('instagram', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/instagram-brands.svg"));
    this.matIconRegistry.addSvgIcon('app-store', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/app-store-brands.svg"));
    this.matIconRegistry.addSvgIcon('apple', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/apple-brands.svg"));
    this.matIconRegistry.addSvgIcon('play-store', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/google-play-brands.svg"));
    this.matIconRegistry.addSvgIcon('windows', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/windows-brands.svg"));

    router.events.pipe(
      withLatestFrom(this.isHandset$),
      filter(([a, b]) => b && a instanceof NavigationEnd)
    ).subscribe((url:any) => {
      if(this.isHandset)
       this.drawer.close();
    });

    router.events.subscribe((url:any) => {
      this.appContent.scrollTo({ top: 0 });
    });

    this.globals.userObservable = new Observable((observer) => {
      let downloadDelay = setInterval(() => {
        if(this.userSessionChecked) {
          observer.next();
          observer.complete();
          clearInterval(downloadDelay);
        }
      }, 500);
    });

    this.globals.siteConfigObservable = new Observable((observer) => {
      let downloadDelay = setInterval(() => {
        if(this.siteConfigDownloaded) {
          observer.next();
          observer.complete();
          clearInterval(downloadDelay);
        }
      }, 500);
    });

    this.globals.siteContentObservable = new Observable((observer) => {
      let downloadDelay = setInterval(() => {
        if(this.siteContentDownloaded) {
          observer.next();
          observer.complete();
          clearInterval(downloadDelay);
        }
      }, 500);
    });

    this.firestore.collection('site').doc('config').valueChanges().subscribe(async (configData: any) => {
      this.globals.subscriptionPlans = configData.subscriptionPlans;
      this.globals.promotionDays = configData.promotionDays;
      this.globals.subscriptionPrice = configData.subscriptionPrice;
      this.globals.subscriptionDays = configData.subscriptionDays;
      this.siteConfigDownloaded = true;
    });

    this.firestore.collection('site').doc('content').valueChanges().subscribe((contentData: any) => {
      this.globals.courses = contentData.courses;
      this.globals.apps = contentData.apps;
      this.globals.mainAd = contentData.mainAd;
      this.siteContentDownloaded = true;
      if(contentData.message) {
        if(contentData.message.show) {
          this.snackbar.open(contentData.message.text, 'Ver más', { duration: 8000, panelClass: 'success-snack-bar', horizontalPosition: 'right', verticalPosition: 'bottom' }).afterDismissed().subscribe((snackbarResult) => {
            if(snackbarResult.dismissedByAction) {
              (window as any).open(contentData.message.url, "_blank");
            }
          });
        }
      }
    });

    this.fireauth.authState.subscribe((user) => {
      this.userSessionChecked = false;
      if(user != null) {
        this.userAuthenticated = true;
        this.userDoc = this.firestore.doc<any>('users/' + user.uid);
        this.user = this.userDoc.valueChanges();
        this.user.subscribe(async (userData: any) => {
          this.globals.userName = userData.name;
          this.globals.userMail = userData.mail;
          this.globals.userPhone = userData.phone;
          this.globals.darkTheme = userData.config.darkTheme;
          this.globals.newSentMessages = userData.newSentMessages;
          this.globals.newReceivedMessages = userData.newReceivedMessages;
          //this.globals.userSponsor = userData.sponsor;
          this.globals.userSubscription = userData.endOfSubscription;
          this.globals.userShareCoupon = userData.shareCoupon;
          this.globals.userSharedCoupon = userData.sharedCoupon;
          this.globals.userUID = user.uid;
          this.globals.newUserCouponUsed = userData.newUserCouponUsed;

          this.userName = userData.name;
          this.userMail = userData.mail;
          this.userConfig = userData.config;
          this.globals.darkTheme = this.userConfig.darkTheme;
          if(this.globals.darkTheme) {
            overlayContainer.getContainerElement().classList.add('dark-theme');
          } 
          else {
            overlayContainer.getContainerElement().classList.remove('dark-theme');
          }
          if(userData.sponsor) {
            this.userSponsor = userData.sponsor;
            this.firestore.collection('sponsors').doc(this.userSponsor.id).ref.get().then((sponsor) => {
              this.globals.userSponsor = sponsor.data();
              if((((userData.lastAdSeen + 1000*60*60*24) < Date.now()) || userData.lastAdSeen == null) && this.globals.userSponsor.ad?.url) {
                this.dialog.open(SponsorAdDialog, {
                  width: '600px',
                  data: this.globals.userSponsor,
                  autoFocus: false
                }).afterClosed().subscribe(() => {
                  this.firestore.collection('users').doc(this.globals.userUID).update({
                    lastAdSeen: Date.now()
                  })
                });
              }
            });
          }

          if(userData.photoURL != '')
            this.userPhotoURL = userData.photoURL;
          else
            this.userPhotoURL = 'https://plataforma.nodoelectronico.com/assets/users/robot-1-azul-claro.png';
          this.userUID = user.uid;
          
          this.firestore.collection('users').doc(this.globals.userUID).collection('courses').valueChanges().subscribe(async (coursesData) => {
            if(coursesData.length > 0) {
              this.globals.userCourses = coursesData;
              this.globals.courses.forEach((course, courseIndex) => {
                this.globals.userCourses.forEach((userCourse) => {
                  if(userCourse.path == course.path) {
                    this.globals.courses[courseIndex].hasStarted = true;
                  }
                });
              });
            }
            if(this.globals.userSubscription > Date.now()) {
              let serverTime: any = await this.http.get('https://us-central1-nodo-electronico.cloudfunctions.net/getTime').toPromise();
              if((Date.now() + 3600000) < serverTime.time) {
                this.globals.userSubscription = 0;
                this.router.navigateByUrl('/subscripcion');
                this.snackbar.open('Hay un error con la fecha de tu computadora, no se podrá acceder al contenido de la plataforma ', 'OK', { duration: 10000, panelClass: 'error-snack-bar', horizontalPosition: 'center', verticalPosition: 'top' }).afterDismissed().subscribe(() => {
                  this.userSessionChecked = true;
                });
              }
              else {
                this.userSessionChecked = true;
              }
            }
            else {
              this.userSessionChecked = true;
            }
          });
        });
      }
      else {
        this.userAuthenticated = false;
        this.userSessionChecked = true;
      }
    });
  }

  logoutUser() {
    this.fireauth.signOut();
    this.userAuthenticated = false;
    this.darkThemeEnabled = true;
    this.globals.userUID = null;
    this.globals.userName = null;
    this.globals.userMail = null;
    this.globals.userPhone = null;
    this.globals.userSubscription = null;
    this.globals.userShareCoupon = null;
    this.globals.userCourses = null;
    this.globals.courses.forEach((course) => {
      course.hasStarted = false;
    })
    this.router.navigate(['/acceso']);
  }

  darkMode(mode: boolean) {
    this.globals.darkTheme = mode;
    this.darkThemeEnabled = mode;
    if(this.user != null) {
      this.userConfig.darkTheme = mode;
      this.firestore.doc('users/' + this.userUID).update({
        config: this.userConfig
      })
    }
  }
}
