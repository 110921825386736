import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'order-details-dialog',
  templateUrl: './order-details-dialog.html',
  styleUrls: ['./order-details-dialog.scss']
})
export class OrderDetailsDialog {
  public order: any;
  public userUID: string;
  public planSelected: string;
  public processingOrder: boolean = true;
  public orderCreated: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<OrderDetailsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.order = data;
  }

  close(): void {
    this.dialogRef.close(this.orderCreated);
  }
}