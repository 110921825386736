<mat-spinner *ngIf="updatingCartInformation" style="margin:20% auto; margin-bottom: 100%;"></mat-spinner>
<div fxLayout="row wrap" fxLayoutAlign="space-between start" class="store-container" [@fadeAnimation]="'in'" *ngIf="!updatingCartInformation">
  <div fxFlex="62%" fxFlex.lt-md="100%">
    <h1 class="title" style="margin-bottom: 12px;">Proceso de Pago</h1>
    <mat-card *ngIf="!globals.userUID" style="border-radius: 8px;" class="mat-elevation-z0">
      <div [@fadeAnimation]="'in'">
        <br>
        <h2 class="subtitle text-center" style="margin: 0px;">
          Es necesario iniciar sesión o registrarse para realizar la compra
        </h2>
        <br>
        <p class="text-center" style="margin: 4px">
          <button mat-stroked-button color="accent" style="margin-right: 8px;" routerLink="/acceso/pago">Iniciar Sesión</button>
          <button mat-flat-button color="accent" routerLink="/registro/pago">Registrarse</button>
        </p>
        <br>
      </div>
    </mat-card>
    <mat-vertical-stepper [linear]="true" #stepper style="border-radius: 8px;" *ngIf="globals.userUID">
      <mat-step [stepControl]="addressFormGroup">
        <form [formGroup]="addressFormGroup">
          <ng-template matStepLabel>Datos de Envío</ng-template>
          <mat-form-field class="full-width" appearance="outline" color="accent">
            <mat-label>Nombre de quien recibe</mat-label>
            <input matInput autocomplete="off" placeholder="Nombre Apellido Apellido" formControlName="nameCtrl" required style="font-weight: bold !important;" [(ngModel)]="shippingName">
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline" color="accent">
            <mat-label>Dirección (línea 1)</mat-label>
            <input matInput autocomplete="off" placeholder="Calle, número e interior" formControlName="addressLine1Ctrl" required style="font-weight: bold !important;" [(ngModel)]="addressLine1">
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline" color="accent">
            <mat-label>Dirección (línea 2)</mat-label>
            <input matInput autocomplete="off" placeholder="Colonia" formControlName="addressLine2Ctrl" required style="font-weight: bold !important;" [(ngModel)]="addressLine2">
          </mat-form-field>
          <mat-form-field style="margin-right: 8px; width: calc(50% - 8px);" appearance="outline" color="accent">
            <mat-label>Ciudad</mat-label>
            <input matInput autocomplete="off" placeholder="Ciudad" formControlName="cityCtrl" required style="font-weight: bold !important;" [(ngModel)]="city">
          </mat-form-field>
          <mat-form-field style="margin-left: 8px; width: calc(50% - 8px);" appearance="outline" color="accent">
            <mat-label>Estado</mat-label>
            <input matInput autocomplete="off" placeholder="Estado" formControlName="stateCtrl" required style="font-weight: bold !important;" [(ngModel)]="state">
          </mat-form-field>
          <mat-form-field style="margin-right: 8px; width: calc(50% - 8px);" appearance="outline" color="accent">
            <mat-label>País</mat-label>
            <input matInput autocomplete="off" value="México" disabled style="font-weight: bold !important;">
          </mat-form-field>
          <mat-form-field style="margin-left: 8px; width: calc(50% - 8px);" appearance="outline" color="accent">
            <mat-label>Código Postal</mat-label>
            <input matInput autocomplete="off" placeholder="XXXXX" formControlName="zipCtrl" required style="font-weight: bold !important;" [(ngModel)]="zipCode">
          </mat-form-field>
          <p class="text-right">
            <button mat-stroked-button color="accent" matStepperNext>Siguiente</button>
          </p>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Elige el método de pago</ng-template>
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center start">
          <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%" style="padding: 8px">
            <p class="text-center" style="margin: 0px;">
              <img src="../../assets/credit-card.png" alt="Paypal" width="160px">
            <p>
            <h2 class="subtitle text-center" style="margin-bottom: 4px; margin-top: 0px;">Tarjeta de Crédito o Débito con Paypal</h2>
            <p class="text text-center" style="margin-top: 4px">A través de Paypal completa tu pago, utiliza tu cuenta de Paypal o tu tarjeta de Crédito o Débito</p>
            <p class="text-center">
              <button mat-flat-button color="accent" class="oxxo-button" matStepperNext (click)="paymentMethod = 'paypal'">
                <mat-icon style="margin-right: 8px;">credit_card</mat-icon>
                Pagar con Paypal
              </button>
            </p>
          </div>
          <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%" style="padding: 8px" *ngIf="total <= 10000">
            <p class="text-center oxxo-logo">
              <img src="../../assets/oxxo_pay_Grande.png" alt="Oxxo Pay" width="160px">
            <p>
            <h2 class="subtitle text-center" style="margin-bottom: 4px;">Efectivo</h2>
            <p class="text text-center" style="margin-top: 4px">Genera un formato de pago, imprímelo y paga en cualquier sucursal de Oxxo.<br> <strong style="color: #888888;">Oxxo cobrará una comisión de $13.00 MXN</strong></p>
            <p class="text-center">
              <button mat-flat-button color="accent" class="oxxo-button" matStepperNext (click)="createOxxoOrder()">
                <mat-icon style="margin-right: 8px;">receipt</mat-icon>
                Pagar en Oxxo
              </button>
            </p>
            <br>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Pago</ng-template>
        <div *ngIf="paymentMethod === 'paypal'">
          <p class="text">Continúa tu pago a través de paypal o ingresando los datos de tu tarjeta de crédito o débito. Al finalizar tu pago prepararemos tu pedido para su envío.</p>
          <div style="width: 420px; max-width: 100%; margin: auto; background-color: #EEEEEE; border-radius: 8px; padding: 16px;">
            <ngx-paypal [config]="payPalConfig"></ngx-paypal>
          </div>
        </div>
        <div *ngIf="paymentMethod === 'oxxo'">
          <mat-spinner *ngIf="processingOrder" style="margin:16px auto;"></mat-spinner>
          <p *ngIf="processingOrder" class="text text-center">Generando Formato de Pago...</p>
          <div *ngIf="!processingOrder">
            <div id="oxxo-order">
              <h1 class="title text-center">Formato de pago en Oxxo</h1>
              <p class="text text-center" style="margin: 0px"><strong>Cantidad: </strong>${{ oxxoOrder.amount }}.00 MXN</p>
              <p class="text text-center" style="margin: 0px"><strong>Fecha de pedido: </strong>{{ oxxoOrder.date | date:'dd/MM/yy h:mm a' }}</p>
              <p class="text text-center" style="margin-top: 0px"><strong>Pague antes del: </strong>{{ (oxxoOrder.paymentData.expires_at)*1000 | date:'dd/MM/yy' }}</p>
              <div class="text-center reference-code">
                <div style="text-align: center;">
                  <img src="../../assets/oxxo_pay_Grande.png" alt="Oxxo Pay" style="width: 50%; max-width: 320px;">
                </div>
                <p style="margin-bottom: 4px"><strong>Número de referencia</strong></p>
                <img src="{{oxxoOrder.paymentData.barcode_url}}" alt="Codigo de Barras Oxxo">
                <p style="margin-top: 4px">{{ oxxoOrder.paymentData.reference }}</p>
              </div>
              <br>
              <h3>Instrucciones:</h3>
              <ol>
                <li>Imprime o guarda en tu celular este formato de pago. También lo hemos enviado a tu correo.</li>
                <li>Acude a la tienda Oxxo más cercana.</li>
                <li>Indica en caja que quieres realizar un pago con OXXOPay.</li>
                <li>Muestra el formato de pago o dicta al cajero el número de referencia en esta ficha.</li>
                <li>El cajero podría confirmarte el destino del pago: Emmanuel Rojas o Nodo Electrónico</li>
                <li>Realiza el pago correspondiente con pago en efectivo. Oxxo cobrará una comisión adicional de $13.00 MXN.</li>
                <li>Al confirmar tu pago, el cajero te entregará un comprobante impreso. En el comprobante podrás verificar que se haya realizado correctamente. Conserva este comprobante de pago.</li>
                <li>Minutos después recibirás el correo de confirmación de pago y en 2 o 3 días hábiles te enviaremos tu producto.</li>
              </ol>
              <br>
              <h3>Cualquier duda o aclaración comunícate con nosotros</h3>
              <p class="text">
                Cel. 221 288 5096 <br>
                Mail: contacto@nodoelectronico.com
              </p>
            </div>
            <p class="text-center">
              <button mat-stroked-button color="accent" routerLink="/tienda" style="width: 148px;">Finalizar</button>
              <button mat-flat-button color="accent" printSectionId="oxxo-order" [useExistingCss]="true" ngxPrint style="width: 148px; margin-left: 16px;">Imprimir</button> 
            </p>
          </div>
        </div>
      </mat-step>
    </mat-vertical-stepper>    
  </div>
  <div fxFlex="34%" fxFlex.lt-md="100%">
    <div style="width: 100%;">
      <h1 class="text-center title" style="margin-bottom: 12px;">Carrito</h1>
      <mat-card class="mat-elevation-z0" style="border-radius: 8px;">
        <div style="overflow-y: auto; height: calc(100vh - 304px);">
          <table style="width:100%;" *ngIf="globals.cart?.length > 0">
            <thead style="position: sticky; top: 0;">
              <tr *ngIf="globals.cart?.length > 0" >
                <th style="position: sticky; top: 0; width: 50px;"></th>
                <th style="text-align: left; position: sticky; top: 0;"><h3 style="margin-bottom: 4px; margin-top: 0px;">Producto</h3></th>
                <th style="text-align: right; width: 64px; position: sticky; top: 0;"><h3 style="margin-bottom: 4px; margin-top: 0px;" matTooltip="Precio Unitario">P/U</h3></th>
              </tr>
            </thead>
            <mat-divider></mat-divider>
            <tr *ngFor="let product of globals.cart; index as i">
              <td> <img src="{{ product.thumbnailImage.url }}" alt="" style="width: 42px; height: 42px; border-radius: 4px;"> </td>
              <td style="text-align: left;"><h3 class="title product-name">{{ product.name }}</h3> <p style="margin: 4px 0px 12px 0px">Cantidad: {{ product.quantity }}</p></td>
              <td style="text-align: right;"><p style="margin: 4px 0px;">${{ product.price | number:'1.2-2' }}</p></td>
            </tr>
          </table>
          <h3 *ngIf="globals.cart?.length <= 0" class="text-center" style="color: #888888">No hay productos en el carrito</h3>
        </div>
        <br>
        <mat-divider></mat-divider>
        <table style="width:100%; margin-top: 8px;">
          <tr>
            <td style="text-align: right;"></td>
            <td style="text-align: right;"><h3 style="margin: 0px;" class="text">Envío incluído</h3></td>
          </tr>
          <tr>
            <td style="text-align: left;"><h2 style="margin-top: 0px; margin-bottom: 8px;" class="subtitle">Total</h2></td>
            <td style="text-align: right;"><h2 style="margin-top: 0px; margin-bottom: 8px;" class="subtitle">${{ total | number:'1.2-2' }} MXN</h2></td>
          </tr>
        </table>
      </mat-card>
    </div>
  </div>
</div>