import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'lesson-details-dialog',
  templateUrl: './lesson-details-dialog.html',
  styleUrls: ['./lesson-details-dialog.scss']
})
export class LessonDetailsDialog {
  public lesson: any;

  constructor(
    public dialogRef: MatDialogRef<LessonDetailsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sanitizer: DomSanitizer) {
      this.lesson = data;
      console.log(this.lesson);
  }
}