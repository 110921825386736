<mat-spinner *ngIf="downloading" style="margin:20% auto;"></mat-spinner>
<div *ngIf="!downloading" [@fadeAnimation]="'in'">
  <div class="content-container">
    <div fxLayout="row wrap" fxFlexAlign="top center">
      <div fxFlex="70%" fxFlex.lt-md="100%">
        <h1 class="title text-center" style="margin-bottom: 0px;">Cursos</h1>
        <div fxLayout="row wrap" fxFlexAlign="top center">
          <div fxFlex="50%" fxFlex.lt-sm="100%" *ngFor="let course of globals.courses" style="padding: 16px;">
            <div class="main-block mat-elevation-z8" [style]="sanitizer.bypassSecurityTrustStyle('background-image: linear-gradient(' + course.heroBackgroundStartColor + ',' + course.heroBackgroundEndColor + ');')">
              <mat-list>
                <mat-list-item style="height: auto; margin-bottom: 32px;" class="course-info">
                  <img matListAvatar src="{{ course.logoURL }}" alt="...">
                  <h1 matLine class="title course-title" [style.color]="sanitizer.bypassSecurityTrustStyle(course.heroTitleColor)">{{ course.title }}</h1>
                  <p matLine class="text" [style.color]="sanitizer.bypassSecurityTrustStyle(course.heroDescriptionColor)" style="font-size: 16px !important; line-height: 18px;">{{ course.shortDescription }}</p>
                </mat-list-item>
              </mat-list>
              <p class="text-center">
                <img src="{{ course.heroImageURL }}" alt="" width="85%" height="auto">
              </p>
              <p style="margin-bottom: 0px;">
                <button mat-flat-button color="warn" routerLink="/cursos/{{ course.path }}" class="course-button" *ngIf="!course.hasStarted">Ver Curso</button>
                <button mat-flat-button color="warn" routerLink="/cursos/{{ course.path }}/contenido" class="course-button" *ngIf="course.hasStarted">Entrar al Curso</button>
              </p>
            </div>
          </div>
        </div>
        <br>
      </div>
      <div fxFlex="30%" fxFlex.lt-md="100%" style="width: 100%; padding-left: 8px;">
        <div class="side-section">
          <!--
            <h1 class="subtitle" style="margin-bottom: 8px; padding: 0px 16px;" *ngIf="events.length > 0">Nuevo Contenido</h1>
            <mat-card class="date-card mat-elevation-z0" *ngFor="let event of events">
              <h1 class="event-title">{{ event.title }}</h1>
              <p style="margin: 0px;"><button mat-stroked-button style="width: 100%;" routerLink="{{ event.path }}">Entrar</button></p>
            </mat-card>
          -->
          <h1 class="title text-center" style="margin-bottom: 8px;">Aplicaciones</h1>
          <div fxLayout="row wrap" fxFlexAlign="top center">
            <div fxFlex="100%" fxFlex.sm="50%" fxFlex.xs="100%" *ngFor="let app of globals.apps" >
              <mat-list>
                <mat-list-item [@fadeAnimation]="'in'" style="height: auto; margin-bottom: 32px;">
                  <img matListAvatar src="{{ app.icon }}" alt="..." class=" mat-elevation-z8">
                  <h1 matLine class="app-title">{{ app.name }}</h1>
                  <p matLine><button mat-stroked-button color="accent" routerLink="/apps/{{ app.path }}" class="full-width">Ver Aplicación</button></p>
                </mat-list-item>
              </mat-list>
            </div>
            <div style="padding: 0px 16px;" *ngIf="globals.newReceivedMessages && !globals.userSponsor">
              <h1 class="subtitle" style="margin-bottom: 8px;">Notificaciones</h1>
              <p class="text" style="margin: 0px">
                ¡Nuevos mensajes recibidos!
              </p>
              <p class="text-center">
                <button mat-stroked-button color="accent" routerLink="/mensajes" class="full-width">Ver Mensajes</button>
              </p>
            </div>
          </div>
          <mat-card class="sponsor-card mat-elevation-z0" *ngIf="globals.userSponsor">
            <p class="text-center">
              <img src="{{ globals.userSponsor.logo }}" alt="" class="sponsor-logo"><br>
            </p>
            <p class="text-center" style="color: #BBBBBB">
              {{ globals.userSponsor.description }}
            </p>
            <p class="text-center" style="margin-bottom: 0px;">
              <a mat-stroked-button href="{{ globals.userSponsor.googleMaps }}" *ngIf="globals.userSponsor.googleMaps" target="_blank" style="margin: 8px;">
                <mat-icon class="icon-margin">map</mat-icon>
                Ubicación
              </a>
              <a mat-stroked-button href="{{ globals.userSponsor.webpage }}" *ngIf="globals.userSponsor.webpage" target="_blank" style="margin: 8px;">
                <mat-icon class="icon-margin">public</mat-icon>
                Sitio Web
              </a>
            </p>
            <p class="text-center" style="margin-top: 0px;">
              <a mat-icon-button class="icon-margin" href="https://wa.me/{{ globals.userSponsor.whatsapp }}" *ngIf="globals.userSponsor.whatsapp" target="_blank">
                <mat-icon svgIcon="whatsapp" style="color:#55FFAA"></mat-icon>
              </a>
              <a mat-icon-button class="icon-margin" href="{{ globals.userSponsor.facebook }}" *ngIf="globals.userSponsor.facebook" target="_blank">
                <mat-icon svgIcon="facebook" style="color:#88AAFF"></mat-icon>
              </a>
              <a mat-icon-button class="icon-margin" href="{{ globals.userSponsor.youtube }}" *ngIf="globals.userSponsor.youtube" target="_blank">
                <mat-icon svgIcon="youtube" style="color:#FF3333"></mat-icon>
              </a>
              <a mat-icon-button class="icon-margin" href="{{ globals.userSponsor.twitter }}" *ngIf="globals.userSponsor.twitter" target="_blank">
                <mat-icon svgIcon="twitter" style="color:#88AAFF"></mat-icon>
              </a>
              <a mat-icon-button href="{{ globals.userSponsor.instagram }}" *ngIf="globals.userSponsor.instagram" target="_blank">
                <mat-icon svgIcon="instagram" style="color:#AA72FF"></mat-icon>
              </a>
            </p>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>