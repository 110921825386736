import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'electronicComponentParameter'
})
export class ElectronicComponentParameterPipe implements PipeTransform {

  transform(value: any, args?: { type: string, text?: string }): any {
    switch (args.type) {
      case 'manufacturer':
        switch (value) {
          case 'adafruit': return 'Adafruit';
          case 'analog-devices': return 'Analog Devices';
          case 'arduino': return 'Arduino';
          case 'digi-international': return 'Digi International';
          case 'espressif-systems': return 'Espressif Systems';
          case 'fluke': return 'Fluke';
          case 'ftdi': return 'FTDI';
          case 'fairchild-semiconductor': return 'Fairchild Semiconductor';
          case 'intesc': return 'Intesc';
          case 'linear-technology': return 'Linear Technology';
          case 'maxon': return 'Maxon';
          case 'microchip': return 'Microchip';
          case 'molex': return 'Molex';
          case 'murata': return 'Murata';
          case 'on-semiconductor': return 'On Semiconductor';
          case 'panasonic': return 'Panasonic';
          case 'pololu': return 'Pololu';
          case 'raspberry-pi': return 'Raspberry Pi Foundation';
          case 'sparkfun': return 'Sparkfun';
          case 'steren': return 'Steren';
          case 'seeedstudio': return 'Seeedstudio';
          case 'st-microelectronics': return 'ST Microelectronics';
          case 'texas-instruments': return 'Texas Instruments';
          case 'truper': return 'Trupper';
          case 'vishay': return 'Vishay';
          case 'weller': return 'Weller';
          default:
            return 'Genérico';
        }
      
      case 'package':
        switch (value) {
          case 'axial': return 'Axial';
          case 'bga': return 'BGA';
          case 'pdip': return 'PDIP';
          case 'plcc': return 'PLCC';
          case 'qfp': return 'QFP';
          case 'radial': return 'Radial';
          case 'soic': return 'SOIC';
          case 'sop': return 'SOP';
          case 'sot23': return 'SOT23';
          case 'sot223': return 'SOT223';
          case 'to92': return 'TO92';
          case 'to220': return 'TO220';
          case '0402': return '0402';
          case '0603': return '0603';
          case '0805': return '0805';
          case '1206': return '1206';
          case 'pcb': return 'PCB';
          case 'motor': return 'Motor';
          case 'cable': return 'Cable';
          default:
            return 'Otro';
        }
      
      case 'category':
        switch (value) {
          case 'actuators': return 'Actuadores';
          case 'cables-wires': return 'Cables y Alambres';
          case 'integrated-circuits': return 'Circuitos Integrados';
          case 'passive-components': return 'Componentes Pasivos';
          case 'connectors': return 'Conectores';
          case 'power-products': return 'Dispositivos de Alimentación';
          case 'electromechanical': return 'Electromecánico';
          case 'test-equipment': return 'Equipo de Medición';
          case 'tools': return 'Herramientas';
          case 'industrial': return 'Industrial';
          case 'communication-modules': return 'Módulos de Comunicación';
          case 'optoelectronics': return 'Optoelectrónicos';
          case 'organizers-boxes': return 'Organizadores y Cajas';
          case 'displays': return 'Pantallas';
          case 'prototyping-manufacturing': return 'Prototipado y Fabricación';
          case 'discrete-semiconductors': return 'Semiconductores Discretos';
          case 'sensors': return 'Sensores';
          case 'software': return 'Software';
          case 'development-boards': return 'Tarjetas de Desarrollo';
          default:
            return 'Otro';
        }
      
      case 'subcategory':
        switch (value) {
          case 'motor-accesories': return 'Accesorios de Actuadores';
          case 'motors': return 'Motores';
          case 'servomotors': return 'Servomotores';
          case 'stepper-motors': return 'Motores a Pasos';
          case 'fans': return 'Ventiladores';
          case 'wires': return 'Alambre';
          case 'cable': return 'Cable';
          case 'data-cable': return 'Cables de datos';
          case 'dupont': return 'Dupont';
          case 'battery-cables': return 'Cables para baterías';
          case 'prototyping-cables': return 'Prototipos';
          case 'opamps': return 'Amplificadores Operacionales';
          case 'logic-gates': return 'Compuertas Lógicas';
          case 'digital-circuits': return 'Circuitos Digitales';
          case 'motor-drivers': return 'Drivers de Motores';
          case 'memories': return 'Memorias';
          case 'microcontrollers': return 'Microcontroladores';
          case 'voltage-regulators': return 'Reguladores de Voltaje';
          case 'capacitors': return 'Capacitores';
          case 'crystals': return 'Cristales';
          case 'inductors': return 'Inductores';
          case 'potentiometers-trimmers': return 'Potenciómetros y Trimmers';
          case 'resistors': return 'Resistores';
          case 'data-conectors': return 'Conectores de Datos';
          case 'headers': return 'Headers';
          case 'batteries': return 'Conectores para baterías';
          case 'prototyping-connectors': return 'Conectores para prototipos';
          case 'screw-terminals': return 'Terminales Atornillables';
          case 'batteries': return 'Baterías';
          case 'battery-chargers': return 'Cargadores de Baterías';
          case 'solar-cells': return 'Celdas Solares';
          case 'power-supply': return 'Fuentes de Voltaje';
          case 'transformers': return 'Transformadores';
          case 'electromechanical-accesories': return 'Accesorios para Electromecánicos';
          case 'relays': return 'Relevadores';
          case 'switches': return 'Interruptores';
          case 'electromechanical-audio': return 'Audio';
          case 'equipment-accesories': return 'Accesorios de Equipos de Medición';
          case 'logic-analizers': return 'Analizadores de Estados Lógicos';
          case 'multimeters': return 'Multímetros';
          case 'osciloscopes': return 'Osciloscopios';
          case 'tool-accesories': return 'Accesorios de Herramientas';
          case 'soldering': return 'Cautines';
          case 'screwdrivers': return 'Desarmadores';
          case 'machining': return 'Maquinado';
          case 'tweezers': return 'Pinzas';
          case 'soldering-suckers': return 'Succionadores de Soldadura';
          case 'industrial-accesories': return 'Accesorios Industriales';
          case 'plcs': return 'PLCs';
          case 'industrial-relays': return 'Relevadores Industriales';
          case 'industrial-sensors': return 'Sensores Industriales';
          case 'bluetooth-modules': return 'Bluetooth';
          case 'cellular-modules': return 'Celular';
          case 'lora-modules': return 'LORA';
          case 'rf-modules': return 'RF';
          case 'sigfox-modules': return 'Sigfox';
          case 'usb-modules': return 'USB';
          case 'xbee-modules': return 'Xbee';
          case 'photodetectors': return 'Fotodetectores';
          case 'leds': return 'LEDs';
          case 'optocouplers': return 'Optoacopladores';
          case 'toolboxes': return 'Cajas de Herramienta';
          case 'circuit-cabinets': return 'Gabinetes para circuito';
          case 'organizers': return 'Organizadores';
          case 'seven-segments': return '7 Segmentos';
          case 'lcd': return 'LCD';
          case 'oled': return 'OLED';
          case 'tft': return 'TFT';
          case 'ic-socket': return 'Bases para Circuito Integrado';
          case 'fuses': return 'Fusibles';
          case 'power-dissipators': return 'Disipadores';
          case 'copper-boards': return 'Placas de Cobre';
          case 'perforated-boards': return 'Placas perforadas';
          case 'protoboards': return 'Protoboards';
          case 'solder': return 'Soldadura';
          case 'diodes': return 'Diodos';
          case 'mosfet': return 'Mosfet';
          case 'scrs': return 'SCRs';
          case 'transistors': return 'Transistores';
          case 'triacs': return 'Triacs';
          case 'audio-sensors': return 'Audio';
          case 'enviroment-sensors': return 'Ambiental';
          case 'distance-proximity-sensors': return 'Distancia y proximidad';
          case 'movement-position-sensors': return 'Posición y Movimiento';
          case 'temperature-sensors': return 'Temperatura';
          case 'calculators-software': return 'Calculadoras';
          case 'design-software': return 'Diseño';
          case 'tools-software': return 'Herramientas';
          case 'programming': return 'Programación';
          case 'simulation': return 'Simulación';
          case 'computer-boards': return 'Computadoras';
          case 'fpga-boards': return 'FPGAs';
          case 'iot-boards': return 'IoT';
          case 'microcontroller-boards': return 'Microcontroladores';
          default:
            return 'Otro';
        }
      
      case 'technical-specification-value':
        let specificationValue: string;
        if(value > 1000000000) {
          specificationValue = Math.round(value/1000000000) + ' G';
        }
        else {
          if(value > 1000000) {
            specificationValue = Math.round(value/1000000) + ' M';
          }
          else {
            if(value > 1000) {
              specificationValue = Math.round(value/1000) + ' K';
            }
            else {
              if(value >= 1) {
                specificationValue = value + ' ';
              }
              else {
                if(value > 0.001) {
                  specificationValue = Math.round(value/0.001) + ' m';
                }
                else {
                  if(value > 0.000001) {
                    specificationValue = Math.round(value/0.000001) + ' µ';
                  }
                  else {
                    if(value > 0.000000001) {
                      specificationValue = Math.round(value/0.000000001) + ' n';
                    }
                    else {
                      if(value > 0) {
                        specificationValue = Math.round(value/0.000000000001) + ' p';
                      }
                      else {
                        if(value < -1000000000) {
                          specificationValue = Math.round(value/1000000000) + ' G';
                        }
                        else {
                          if(value < -1000000) {
                            specificationValue = Math.round(value/1000000) + ' M';
                          }
                          else {
                            if(value < -1000) {
                              specificationValue = Math.round(value/1000) + ' K';
                            }
                            else {
                              if(value < -1) {
                                specificationValue = Math.round(value) + ' ';
                              }
                              else {
                                if(value < -0.001) {
                                  specificationValue = Math.round(value/0.001) + ' m';
                                }
                                else {
                                  if(value < -0.000001) {
                                    specificationValue = Math.round(value/0.000001) + ' µ';
                                  }
                                  else {
                                    if(value < -0.000000001) {
                                      specificationValue = Math.round(value/0.000000001) + ' n';
                                    }
                                    else {
                                      if( value == 0) {
                                        specificationValue = '0';
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        switch (args.text) {
          case 'height':
            return (specificationValue + 'm');
          case 'calibre':
            return specificationValue + 'AWG';
          case 'capacitance': 
            return (specificationValue + 'F');
          case 'dropout-voltage':
            return (specificationValue + 'V');
          case 'current-consumption':
            return (specificationValue + 'A');
          case 'current-consumption-min':
            return (specificationValue + 'A');
          case 'current-consumption-max':
            return (specificationValue + 'A');
          case 'power-consumption':
            return (specificationValue + 'W');
          case 'output-current':
            return (specificationValue + 'A');
          case 'peak-output-current':
            return (specificationValue + 'A');
          case 'diameter':
            return (specificationValue + 'm');
          case 'power-dissipation':
            return (specificationValue + 'W');
          case 'inductance':
            return (specificationValue + 'H');
          case 'large':
            return (specificationValue + 'm');
          case 'weight':
            return (specificationValue + 'g');
          case 'resistance':
            return (specificationValue + 'Ω');
          case 'temp-min':
            return (specificationValue + '°C');
          case 'temp-max':
            return (specificationValue + '°C');
          case 'vca':
            return (specificationValue + 'VCA');
          case 'vcc':
            return (specificationValue + 'V');
          case 'vin-min':
            return (specificationValue + 'V');
          case 'vin-max':
            return (specificationValue + 'V');
          case 'vdd':
            return (specificationValue + 'V');
          case 'vdd-min':
            return (specificationValue + 'V');
          case 'vdd-max':
            return (specificationValue + 'V');
          case 'vdd-min-negative':
            return (specificationValue + 'V');
          case 'vdd-max-negative':
            return (specificationValue + 'V');
          case 'vout':
            return (specificationValue + 'V');
          case 'vout-min':
            return (specificationValue + 'V');
          case 'vout-max':
            return (specificationValue + 'V');
          case 'diode-voltage':
            return (specificationValue + 'V');
          case 'reverse-voltage':
            return (specificationValue + 'V');
          case 'collector-current':
            return (specificationValue + 'A');
          case 'dc-current-gain': return specificationValue;
          case 'small-signal-current-gain': return specificationValue;
          case 'min-gain': return specificationValue;
          case 'max-gain': return specificationValue;
          case 'torque':
            return (specificationValue + 'g/cm');
          case 'rpms':
            return (specificationValue + 'RPM');
          case 'gear-ratio':
            return (specificationValue + ':1');
          case 'steps-per-revolution':
            return (specificationValue);
          case 'rotation-angle':
            return (specificationValue) + '°';
          case 'power-consumption-power-save':
            return (specificationValue + 'A');
          case 'freq-op-max':
            return (specificationValue + 'Hz');
          case 'freq-int-osc-min':
            return (specificationValue + 'Hz');
          case 'freq-int-osc-max':
            return (specificationValue + 'Hz');
          case 'adc-channels': return specificationValue;
          case 'cans': return specificationValue;
          case 'analog-comparators': return specificationValue;
          case 'gpio': return specificationValue;
          case 'i2cs': return specificationValue;
          case 'pins': return specificationValue;
          case 'pwms': return specificationValue;
          case 'spis': return specificationValue;
          case '8bits-timers': return specificationValue;
          case '10bits-timers': return specificationValue;
          case '16bits-timers': return specificationValue;
          case '32bits-timers': return specificationValue;
          case 'uarts': return specificationValue;
          case 'adc-bits': return specificationValue;
          case 'eeprom-memory':
            return (specificationValue + 'Bytes');
          case 'flash-memory':
            return (specificationValue + 'Bytes');
          case 'ram-memory':
            return (specificationValue + 'Bytes');
          default:
            return specificationValue;
        }
      
      case 'technical-specification-text':
        switch (value) {
          case 'height': return 'Altura';
          case 'calibre': return 'Calibre';
          case 'capacitance': return 'Capacitancia';
          case 'dropout-voltage': return 'Caída de Voltaje';
          case 'current-consumption': return 'Consumo de Corriente';
          case 'current-consumption-min': return 'Consumo de Corriente Mínimo';
          case 'current-consumption-max': return 'Consumo de Corriente Máximo';
          case 'power-consumption': return 'Consumo de Energía';
          case 'output-current': return 'Corriente de Salida';
          case 'peak-output-current': return 'Corriente de Salida Pico';
          case 'diameter': return 'Diámetro';
          case 'power-dissipation': return 'Disipación de Potencia';
          case 'inductance': return 'Inductancia';
          case 'large': return 'Longitud';
          case 'weight': return 'Peso';
          case 'resistance': return 'Resistencia';
          case 'temp-min':  return 'Temperatura mínima de operación';
          case 'temp-max': return 'Temperatura máxima de operación';
          case 'vca': return 'Voltaje de Alimentación de Alterna';
          case 'vcc': return 'Voltaje de Alimentación';
          case 'vin-min': return 'Voltaje de Entrada Mínimo';
          case 'vin-max': return 'Voltaje de Entrada Máximo';
          case 'vdd': return 'Voltaje de Operación';
          case 'vdd-min': return 'Voltaje de Operación Mínimo';
          case 'vdd-max': return 'Voltaje de Operación Máximo';
          case 'vdd-min-negative': return 'Voltaje de Operación Negativo Mínimo';
          case 'vdd-max-negative': return 'Voltaje de Operación Negativo Máximo';
          case 'vout': return 'Voltaje de Salida';
          case 'vout-min': return 'Voltaje de Salida Mínimo';
          case 'vout-max': return 'Voltaje de Salida Máximo';
          case 'diode-voltage': return 'Voltaje de Polarización de Diodo';
          case 'reverse-voltage': return 'Voltaje de Polarización Inversa';
          case 'collector-current': return 'Corriente de Colector';
          case 'dc-current-gain': return 'Ganancia de Corriente DC';
          case 'small-signal-current-gain': return 'Ganancia de Corriente de pequeña señal';
          case 'min-gain': return 'Ganancia Mínima';
          case 'max-gain': return 'Ganancia Máxima';
          case 'torque': return 'Torque';
          case 'rpms': return 'Velocidad de Rotación';
          case 'gear-ratio': return 'Relación de Engranaje';
          case 'steps-per-revolution': return 'Pasos por vuelta';
          case 'rotation-angle': return 'Ángulo de Rotación';
          case 'power-consumption-power-save': return 'Consumo de Corriente con Ahorro de Energía';
          case 'freq-op-max': return 'Frecuencia máxima de operación';
          case 'freq-int-osc-min': return 'Frecuencia mínima de oscilador interno';
          case 'freq-int-osc-max': return 'Frecuencia máxima de oscilador interno';
          case 'adc-channels': return 'Número de Canales Analógicos';
          case 'cans': return 'Número de CANs';
          case 'analog-comparators': return 'Número de Comparadores Analógicos';
          case 'gpio': return 'Número de Entradas/Salidas Digitales';
          case 'i2cs': return 'Número de I2Cs';
          case 'pins': return 'Número de Pines';
          case 'pwms': return 'Número de PWMs';
          case 'spis': return 'Número de SPIs';
          case '8bits-timers': return 'Número de Timers de 8 Bits';
          case '8bits-timers': return 'Número de Timers de 10 Bits';
          case '16bits-timers': return 'Número de Timers de 16 Bits';
          case '32bits-timers': return 'Número de Timers de 32 Bits';
          case 'uarts': return 'Número de UARTs';
          case 'adc-bits': return 'Resolución lectura ADC';
          case 'eeprom-memory': return 'Tamaño de Memoria EEPROM';
          case 'flash-memory': return 'Tamaño de Memoria Flash';
          case 'ram-memory': return 'Tamaño de Memoria RAM';
          default:
            return 'Otro';
        }
      
      default:
        break;
    }
    return null;
  }

}