import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-notice-of-privacy',
  templateUrl: './notice-of-privacy.component.html',
  styleUrls: ['./notice-of-privacy.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(300)
      ]),
      transition(':leave',
        animate(300, style({opacity: 0})))
    ])
  ]
})
export class NoticeOfPrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
