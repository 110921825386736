<div class="login-background">
  <section class="mat-typography page-container">
    <div *ngIf="!userAuthenticated" [@fadeAnimation]="'in'">
      <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="center center" style="min-height: calc(100vh - 248px);">
        <span class="dark-theme" style="margin:16px auto;">
          <mat-spinner *ngIf="!sesionChecked"></mat-spinner>
        </span>
        <div fxFlex="960px" fxFlex.lt-md="100%">
          <div *ngIf="sesionChecked">
            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="center center">
              <div fxFlex="50%" class="text-center" fxLayoutAlign="center center" fxHide.xs="true">
                <img src="./../../assets/login-robot.png" alt="login robot" style="width: calc(100% - 16px); height: auto; margin-right: 16px;">
              </div>
              <div fxFlex="100%" class="text-center" fxLayoutAlign="center center" fxHide.gt-xs="true">
                <img src="./../../assets/login-robot-mobile.png" alt="login robot" style="width: 100%; height: auto;">
              </div>
              <div fxFlex="50%" fxLayoutAlign="center center">
                <mat-card style="border-radius: 8px; margin: 16px 0px;" class="mat-elevation-z24" [ngClass]="{'dark-theme': false}">
                  <h1 class="title text-center">Iniciar Sesión</h1>
                  <form (keydown.enter)="loginUser()">
                    <mat-form-field class="full-width" appearance="outline" color="accent">
                      <mat-label>Correo Electrónico</mat-label>
                      <input matInput name="user_mail" type="email" placeholder="usuario@correo.com" [(ngModel)]="userMail" [disabled]="authenticating" [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                    <mat-form-field class="full-width"  appearance="outline" color="accent">
                      <mat-label>Contraseña</mat-label>
                      <input matInput name="user_password" type="password" placeholder="········" [(ngModel)]="userPassword" [disabled]="authenticating" [ngModelOptions]="{standalone: true}" [type]="hide ? 'password' : 'text'">
                      <mat-icon style="cursor: pointer" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>
                    <p class="text-center">
                      <button mat-flat-button color="accent" (click)="loginUser()" [disabled]="authenticating" class="full-width" style="font-size: 16px;">Iniciar Sesión</button>
                    </p>
                    <p class="text-center">
                      <a mat-stroked-button color="accent" [disabled]="authenticating" class="full-width" (click)="registerUser()">Registrarse</a>
                    </p>
                    <br>
                    <p class="mat-caption text-center">¿No recuerdas tu contraseña?</p>
                    <p class="text-center">
                      <button (click)="resetUserPassword()" mat-button color="accent" [disabled]="authenticating" class="full-width">Recuperar Contraseña</button>
                    </p>
                  </form>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>  
</div>