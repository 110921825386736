<section>
  <h1 mat-dialog-title class="subtitle">Examen</h1>
  <div mat-dialog-content>
    <p>{{ this.question }}</p>
    <div style="padding-left: 16px">
      <mat-radio-group [(ngModel)]="selectedAnswer">
        <mat-radio-button [value]="answer" class="test-answer" *ngFor="let answer of answers">
          {{ answer.text }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div mat-dialog-actions mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>Cerrar</button>
    <button mat-raised-button color="accent" style="margin-left: 8px;" (click)="evaluateTest()">Evaluar</button>
  </div>
</section>