import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'oxxo-pay-dialog',
  templateUrl: './oxxo-pay-dialog.html',
  styleUrls: ['./oxxo-pay-dialog.scss']
})
export class OxxoPayDialog {
  public order: any;
  public userUID: string;
  public planSelected: string;
  public processingOrder: boolean = true;
  public orderCreated: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<OxxoPayDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: HttpClient,
    public firestore: AngularFirestore) {
      this.userUID = data.userUID;
      this.planSelected = data.plan;
      this.createOxxoOrder();
  }

  createOxxoOrder() {
    this.processingOrder = true;
    this.http.get('https://us-central1-nodo-electronico.cloudfunctions.net/createOxxoPayment?uid=' + this.userUID + '&plan=' + this.planSelected).subscribe((result: any) => {
      console.log(result);
      this.firestore.doc('orders/' + result.orderID).valueChanges().subscribe((response) => {
        this.order = response;
        this.processingOrder = false;
        this.orderCreated = true;
      });
    });
  }

  close(): void {
    this.dialogRef.close(this.orderCreated);
  }
}