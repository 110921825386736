<div class="container" style="width: 600px; max-width: 85%; text-align:justify; margin: auto" [@fadeAnimation]="'in'">
  <br>
  <p>En el Nodo Electrónico estamos realmente comprometidos con nuestros usuarios y clientes, por lo que la información que pedimos de ellos la tratamos con sumo cuidado y es utilizada solamente con la finalidad de poder ofrecer un mejor servicio.</p>
  <p>Este Aviso de Privacidad describe el tratamiento que recibirá la información que le das al Nodo Electrónico cuando le proporcionas tus datos mediante su página web nodoelectronico.com. Al proporcionar tu información con el Nodo Electrónico se entiende que aceptas y das consentimiento expreso e irrestricto respecto del tratamiento que recibirá tu información de acuerdo a lo establecido en el Aviso de Privacidad.</p>
  <h3>DATOS PERSONALES Y LA FINALIDAD PRINCIPAL Y SECUNDARIA DE SU TRATAMIENTO</h3>
  <h5>Finalidad principal:</h5>
  <p>En virtud de que a través del Nodo Electrónico podamos ofrecer una mejor experiencia, el Nodo Electrónico requiere algunos o todos los datos personales que a continuación se señalan y para las siguientes finalidades.<br>
  Nombre: Necesario para conocerte y comunicarnos contigo adecuadamente.<br>
  Correo Electrónico: Utilizado para establecer comunicación contigo para mantenerte durante el tiempo en que te ofrecemos el servicio.<br>
  Teléfono: Igualmente utilizado para establecer comunicación contigo para consultar alguna duda respecto al servicio que te estamos ofreciendo.<br>
  Información sobre tus intereses sobre nuestros cursos. Nos servirá de ayuda para ofrecerte una atención más directa respecto a tus intereses.</p>
  <h5>Finalidades secundarias:</h5>
  <p>Para el Nodo Electrónico es muy importante conocer a sus clientes con la pura intención de mejorar sus productos y servicios, así como para poder establecer comunicación para darte a conocer las novedades, avances, promociones, lanzamientos y noticias, por lo que podrá utilizarse esta información para efectos de (i) mercadotecnia, publicidad, prospección comercial y/o estadísticas, (ii) identificarte, contactarte e informarte sobre nuestras campañas de comercialización, promociones, sorteos o concursos y tu participación (si la hubiere), y (iii) hacerte llegar encuestas de satisfacción. Es importante señalar que, aunque al Nodo Electrónico le interesa estar en contacto con sus lectores y clientes, siempre es posible contactar a través del correo contacto@nodoelectronico.com para informar y especificar que no estás de acuerdo en que se utilice tu información para dichos efectos, mediante escrito libre con firma autógrafa y adjuntando copia de tu identificación oficial.</p>
  <h3>DERECHOS ARCO Y OTROS</h3>
  <p>Todos nuestros clientes tienen derechos de acceso, rectificación, cancelación y oposición al tratamiento de sus datos personales (“Derechos ARCO”), así como revocar su consentimiento, cuando sea aplicable, y limitar el uso y divulgación de sus datos personales; por lo que en caso de que quieras ejercer cualquiera de ellos sólo necesitas contactar a contacto@nodoelectronico.com informando, a través de escrito libre con firma autógrafa, lo siguiente:</p>
  <p>Tu nombre o de la persona que te representa.<br>
  Correo electrónico para comunicar la respuesta a tu solicitud.<br>
  Describir de forma clara y precisa los datos personales respecto de los cuales buscas ejercer algún Derecho ARCO o revocar tu consentimiento o limitar su uso o divulgación.<br>
  En el caso específico de rectificación, es necesario especificar las modificaciones a realizar o la forma en que deseas limitar el uso o divulgación de tus datos personales.</p>
  <p>En todo caso, siempre deberás adjuntar a tu correo, copia de (i) tu identificación oficial, o (ii) la identificación de tu representante legal, así como carta poder firmada por ti, con los cuales Nodo Electrónico podrá identificar que quien está solicitando el ejercicio de cualquiera de los Derechos ARCO o revocación a su consentimiento o la limitación/divulgación de sus datos personales, efectivamente eres tú (o representante legal tuyo), el titular de dichos datos personales.</p>
  <p>Para confirmar que Nodo Electrónico ha recibido tu solicitud, te hará llegar al correo que le hayas proporcionado un acuse de recepción. Tan pronto reciba tu solicitud, Nodo Electrónico revisará y analizará tu solicitud, junto con la documentación que le hayas proporcionado y en un plazo no superior a 30 días recibirás una respuesta a tu solicitud, la cual será efectiva dentro de los siguientes 15 días. En caso de que Nodo Electrónico necesite un mayor plazo para dar respuesta a tu solicitud, te enviará un correo informándote la ampliación al plazo, así como los motivos por los cuales requiere dicha ampliación.</p>
  <p>Nodo Electrónico no proporciona o transfiere tu información a terceros, sin que exista causa justificada de acuerdo a la legislación aplicable o sin tu consentimiento cuando sea aplicable.</p>
  <p>Nodo Electrónico pone a tu disposición este Aviso de Privacidad en su página de internet [nodoelectronico.com] para que tengas acceso al mismo en cualquier momento, y puedas revisarlo, guardarlo o imprimirlo, si así lo deseas. Es importante mencionar que el presente Aviso de Privacidad podrá modificarse en cualquier momento, en cuyo caso, la nueva versión estará disponible en la página de internet antes señalada, la cual es compromiso del Nodo Electrónico mantenerla actualizada.</p>
  <br>
</div>