import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from "@angular/common/http";
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Globals } from '../app-globals';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(300)
      ]),
      transition(':leave',
        animate(300, style({opacity: 0})))
    ])
  ]
})
export class SignUpComponent implements OnInit, OnDestroy {
  public userName: string = '';
  public userMail: string = '';
  public userPhone: string = '';
  public userPassword: string = '';
  public confirmationPassword: string = '';
  public authenticating: boolean = false;
  public hide: boolean = true;
  public redirectRoute: string = '';

  constructor(
    public globals: Globals,
    public fireauth: AngularFireAuth,
    public router: Router,
    public route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public firestore: AngularFirestore,
    public http: HttpClient
  ) { }

  ngOnInit(): void {
    this.globals.darkTheme = false;
    this.route.paramMap.subscribe((params) => {
      if(params)
        this.redirectRoute = params.get('redirectRoute');
    });
  }

  ngOnDestroy() {
    this.globals.darkTheme = true;
  }

  registerUser() {
    this.authenticating = true;
    if(this.userName.trim() != '') {
      if(this.userMail.trim() != '') {
        if(this.userPhone.trim() != '') {
          if(this.userPassword.trim() != '') {
            if(this.confirmationPassword.trim() != '') {
              if(this.userPassword == this.confirmationPassword) {
                this.fireauth.signOut();
                this.fireauth.createUserWithEmailAndPassword(this.userMail,this.userPassword).then((res) => {
                  if(res.user != null) {
                    let newUser: any = {
                      name: this.userName,
                      mail: this.userMail,
                      photoURL: '',
                      phone: this.userPhone,
                      shareCoupon: this.getRandomUserCoupon(),
                      creationDate: Date.now(),
                      config: {
                        darkTheme: true
                      }
                    }
                    this.firestore.collection('users').doc(res.user.uid).set(newUser).then((userDoc) => {
                      this.http.get('https://us-central1-nodo-electronico.cloudfunctions.net/sendWelcomeEmail?uid=' + res.user.uid).subscribe(() => {
                        if(this.redirectRoute) {
                          this.router.navigateByUrl('/' + this.redirectRoute);
                        }
                        else {
                          this.router.navigateByUrl('/suscripcion');
                          this.snackBar.open('¡Gracias por registrarte! Ahora sólo falta activar tu suscripción', 'OK', { duration: 5000, panelClass: 'success-snack-bar', horizontalPosition: 'center', verticalPosition: 'top' });
                        }
                      });
                    });
                  }
                }).catch((err)=> {
                  this.authenticating = false;
  
                  if(err.code == 'auth/email-already-in-use') {
                    this.snackBar.open('Error, el correo electrónico ya fue registrado', 'OK', {
                      duration: 5000,
                      panelClass: 'error-snack-bar',
                      horizontalPosition: 'center',
                      verticalPosition: 'top'
                    });
                  }
  
                  if(err.code == 'auth/invalid-email') {
                    this.snackBar.open('Error, formato correo electrónico inválido', 'OK', {
                      duration: 5000,
                      panelClass: 'error-snack-bar',
                      horizontalPosition: 'center',
                      verticalPosition: 'top'
                    });
                  }
            
                  if(err.code == 'auth/operation-not-allowed') {
                    this.snackBar.open('Error, operación no permitida, envíe un correo a contacto@nodoelectronico.com', 'OK', {
                      duration: 20000,
                      panelClass: 'error-snack-bar',
                      horizontalPosition: 'center',
                      verticalPosition: 'top'
                    });
                  }
            
                  if(err.code == 'auth/weak-password') {
                    this.snackBar.open('Error, contraseña demasiado débil, incluye números y/o mayúsculas', 'OK', {
                      duration: 5000,
                      panelClass: 'error-snack-bar',
                      horizontalPosition: 'center',
                      verticalPosition: 'top'
                    });
                    this.userPassword = '';
                    this.confirmationPassword = '';
                  }
                });
              }
              else {
                this.authenticating = false;
                this.snackBar.open('Error, las contraseñas son diferentes', 'OK', {
                  duration: 5000,
                  panelClass: 'error-snack-bar',
                  horizontalPosition: 'center',
                  verticalPosition: 'top'
                });
              }
            }
            else {
              this.authenticating = false;
              this.snackBar.open('Error, ingresa la confirmación de contraseña', 'OK', {
                duration: 5000,
                panelClass: 'error-snack-bar',
                horizontalPosition: 'center',
                verticalPosition: 'top'
              });
            }
          }
          else {
            this.authenticating = false;
            this.snackBar.open('Error, ingresa tu contraseña', 'OK', {
              duration: 5000,
              panelClass: 'error-snack-bar',
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
          }
        }
        else {
          this.authenticating = false;
          this.snackBar.open('Error, ingresa un teléfono válido', 'OK', {
            duration: 5000,
            panelClass: 'error-snack-bar',
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
        }
      }
      else {
        this.authenticating = false;
        this.snackBar.open('Error, ingresa tu correo electrónico', 'OK', {
          duration: 5000,
          panelClass: 'error-snack-bar',
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
    }
    else {
      this.authenticating = false;
      this.snackBar.open('Error, ingresa tu nombre', 'OK', {
        duration: 5000,
        panelClass: 'error-snack-bar',
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    }
  }

  getRandomUserCoupon(): string {
    // https://gist.github.com/mikelehen/3596a30bd69384624c11
    let PUSH_CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let randChars = [];
    let i: number = 0;
    let id: string = '';
    for (i = 0; i < 10; i++) {
      randChars[i] = Math.floor(Math.random() * 62);
      id += PUSH_CHARS.charAt(randChars[i]);
    }
    return id;
  }
}
