import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { Globals } from '../app-globals';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  public messageText: string = '';
  public chatMessages: any[] = [];
  public lastChatMessages: any[] = [];
  public viewUpdated: boolean = false;
  public gotAllMessages: boolean = false;
  @ViewChild('messagesContainer') private messagesContainer: ElementRef;

  constructor(
    public firestore: AngularFirestore,
    public firedb: AngularFireDatabase,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    this.globals.userObservable.subscribe(() => {
      this.firedb.list('messages/' + this.globals.userUID, ref => ref.orderByChild('date').limitToLast(10)).valueChanges(['child_added']).subscribe((messages) => {
        if(messages.length < 10) {
          this.gotAllMessages = true;
        }
        this.chatMessages = messages;
        this.viewUpdated = false;
        if(this.globals.newReceivedMessages) {
          this.firestore.collection('users').doc(this.globals.userUID).update({ newReceivedMessages: false });
        }
      });
    });
  }

  sendMessage() {
    this.firedb.list('messages/' + this.globals.userUID).push({
      type: 'text-sent',
      text: this.messageText,
      date: Date.now()
    });
    if(!this.globals.newSentMessages) {
      this.firestore.collection('users').doc(this.globals.userUID).update({ newSentMessages: true, lastMessageDate: Date.now(), lastMessageText: this.messageText });
    }
    this.messageText = '';
    this.viewUpdated = false;
  }

  getMoreMessages() {
    let lastMessageDate = 0;
    if(this.lastChatMessages.length == 0) {
      lastMessageDate = this.chatMessages[0].date;
    }
    else {
      lastMessageDate = this.lastChatMessages[0].date;
    }
    this.firedb.list('messages/' + this.globals.userUID, ref => ref.orderByChild('date').endAt(lastMessageDate).limitToLast(11)).valueChanges().subscribe((messages) => {
      if(messages.length < 11) {
        this.gotAllMessages = true;
      }
      messages.splice((messages.length - 1),1);
      this.lastChatMessages.unshift(...messages);
      console.log(this.lastChatMessages);
    });
  }

  ngAfterViewChecked() {     
    if(!this.viewUpdated) {
      try {
        this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
      } catch(err) {}
      this.viewUpdated = true;
    }
  }

}
