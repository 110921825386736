import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from "@angular/flex-layout";
import { HttpClientModule } from '@angular/common/http';
import { KatexModule } from 'ng-katex';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxPrintModule } from 'ngx-print';
import { HighlightJsModule, HIGHLIGHTJS_CONFIG, HighlightJsConfig } from 'ngx-highlight-js';
import { IvyCarouselModule } from 'angular-responsive-carousel';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { environment } from '../environments/environment';

import localeEs from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEs, 'es');
import { Globals } from "./app-globals";

/* Pipes */
import { ElectronicComponentParameterPipe } from '../pipes/electronic-component-parameter.pipe';

/* Pages */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SponsorAdDialog } from "./sponsor-ad-dialog/sponsor-ad-dialog";
import { LoginComponent } from './login/login.component';
import { ResetPasswordDialog } from "./login/reset-password-dialog/reset-password-dialog";
import { SignUpComponent } from './sign-up/sign-up.component';
import { NoticeOfPrivacyComponent } from './notice-of-privacy/notice-of-privacy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { AppDetailsComponent } from './app-details/app-details.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CourseSalePageComponent } from './course-sale-page/course-sale-page.component';
import { CourseContentComponent } from './course-content/course-content.component';
import { LessonDetailsDialog } from "./course-content/lesson-details-dialog/lesson-details-dialog";
import { CourseLessonComponent } from './course-lesson/course-lesson.component';
import { LessonTestDialog } from "./course-lesson/lesson-test-dialog/lesson-test-dialog";
import { ElectronicComponentViewerDialog } from "./electronic-component-viewer-dialog/electronic-component-viewer-dialog";
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { UserAvatarDialog } from "./user-settings/user-avatar-dialog/user-avatar-dialog";
import { OrderDetailsDialog } from "./user-settings/order-details-dialog/order-details-dialog";
import { SubscribeComponent } from './subscribe/subscribe.component';
import { OxxoPayDialog } from "./subscribe/oxxo-pay-dialog/oxxo-pay-dialog";
import { AccessCodeDialog } from "./subscribe/access-code-dialog/access-code-dialog";
import { MessagesComponent } from './messages/messages.component';
import { StoreComponent } from './store/store.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { PaymentComponent } from './payment/payment.component';

@NgModule({
  declarations: [
    AppComponent,
    SponsorAdDialog,
    LoginComponent,
    SignUpComponent,
    ResetPasswordDialog,
    NoticeOfPrivacyComponent,
    TermsAndConditionsComponent,
    AppDetailsComponent,
    DashboardComponent,
    CourseSalePageComponent,
    CourseContentComponent,
    LessonDetailsDialog,
    CourseLessonComponent,
    LessonTestDialog,
    ElectronicComponentViewerDialog,
    ElectronicComponentParameterPipe,
    UserSettingsComponent,
    UserAvatarDialog,
    OrderDetailsDialog,
    SubscribeComponent,
    OxxoPayDialog,
    AccessCodeDialog,
    MessagesComponent,
    StoreComponent,
    ProductDetailsComponent,
    PaymentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    KatexModule,
    RoundProgressModule,
    NgxPayPalModule,
    NgxPrintModule,
    HighlightJsModule,
    IvyCarouselModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    MatDividerModule,
    MatSnackBarModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatBadgeModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatProgressBarModule,
    MatDialogModule,
    MatRippleModule,
    MatTooltipModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatStepperModule,
    MatCheckboxModule,
    MatRadioModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: HIGHLIGHTJS_CONFIG, 
      useValue: { 
        lang: 'arduino'
      } as HighlightJsConfig 
    },
    Globals
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
