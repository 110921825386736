import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from '../app-globals';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-electronic-component-viewer-dialog',
  templateUrl: './electronic-component-viewer-dialog.html',
  styleUrls: ['./electronic-component-viewer-dialog.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(300)
      ]),
      transition(':leave',
        animate(300, style({opacity: 0})))
    ])
  ]
})
export class ElectronicComponentViewerDialog implements OnInit {
  public component: any;
  public technicalSpecifications: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<ElectronicComponentViewerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public firestore: AngularFirestore,
    public route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public globals: Globals,
    public router: Router,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
    if(this.data != null) {
      this.component = this.data;
      let specs: string[] = Object.keys(this.component.technicalSpecifications);
      let values: number[] = Object.values(this.component.technicalSpecifications);
      specs.forEach((specification, index) => {
        this.technicalSpecifications.push({ text: specification, value: values[index] })
      })
    }
  }

}