import { Component, Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'reset-password-dialog',
  templateUrl: 'reset-password-dialog.html',
})
export class ResetPasswordDialog {
  public userMail: string = '';

  constructor(
    public dialogRef: MatDialogRef<ResetPasswordDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fireauth: AngularFireAuth ) {}

  sendResetRequest() {
    this.fireauth.sendPasswordResetEmail(this.userMail);
    this.dialogRef.close(true);
  }

}