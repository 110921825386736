<h1 mat-dialog-title>Reinicio de Contraseña</h1>
<div mat-dialog-content>
  <p>Ingresa el correo electrónico que registraste en este sitio</p>
  <mat-form-field class="full-width" appearance="outline" color="accent">
    <mat-label>Correo Electrónico</mat-label>
    <input matInput name="user_mail" type="email" placeholder="usuario@correo.com" [(ngModel)]="userMail" [ngModelOptions]="{standalone: true}">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close cdkFocusInitial>Cancelar</button>
  <button mat-flat-button color="accent" (click)="sendResetRequest()">Enviar Solicitud de Reinicio de Contraseña</button>
</div>