import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class Globals {
  userName: string;
  darkTheme: boolean = true;
  newSentMessages: boolean;
  newReceivedMessages: boolean;
  userSponsor: any;
  userUID: string;
  userMail: string;
  userPhone: string;
  userSubscription: number;
  userShareCoupon: string;
  userSharedCoupon: any;
  newUserCouponUsed: boolean
  userObservable: Observable<any>;
  promotionDays: number;
  subscriptionPrice: number;
  subscriptionDays: number;
  subscriptionPlans: any;
  siteConfigObservable: Observable<any>;
  electronicComponents: any[];
  electronicComponentsCategory: string;
  dashboardContent: any[];
  courses: any[];
  apps: any[];
  mainAd: any;
  siteContentObservable: Observable<any>;
  userCourses: any[];
  cart: any[];
}