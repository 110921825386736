import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'access-code-dialog',
  templateUrl: './access-code-dialog.html',
  styleUrls: ['./access-code-dialog.scss']
})
export class AccessCodeDialog {
  public coupon: any;

  constructor(
    public dialogRef: MatDialogRef<AccessCodeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.coupon = data;
  }
}