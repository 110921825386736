<div [ngClass]="{ 'dark-theme': globals.darkTheme }" style="height: 100%">
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav dark-gradient dark-theme" fixedInViewport="true" [attr.role]="'dialog'" mode="over" opened="false"  [autoFocus]="false">
      <div fxLayout="column" fxFill>
        <div class="text-center" *ngIf="userPhotoURL && userAuthenticated">
          <img src="{{ userPhotoURL }}" alt="" class="menu-user-avatar">
          <h1 class="user-name">{{ userName }}</h1>
          <h2 class="user-mail">{{ userMail }}</h2>
        </div>
        <img src="../assets/logo-nombre-blanco.png" alt="" height="48px" width="168px" style="margin: 24px;" *ngIf="!userAuthenticated">
        <mat-nav-list class="transparent-background" style="padding-top: 0px">
          <a mat-list-item routerLink="/acceso" class="white-text main-menu-item" *ngIf="!userAuthenticated" (click)="drawer.close()">
            <mat-icon class="icon-margin" matListIcon style="color: #FF8800" aria-hidden="true" role="img">perm_identity</mat-icon>Iniciar Sesión
          </a>
          <a mat-list-item class="white-text main-menu-item" routerLink="/inicio" *ngIf="userAuthenticated" (click)="drawer.close()">
            <mat-icon class="icon-margin" matListIcon style="color: #FF8800" aria-hidden="true" role="img">view_quilt</mat-icon>Inicio
          </a>
        </mat-nav-list>
        <mat-accordion displayMode="flat" class="dark-theme transparent-background">
          <mat-expansion-panel class="mat-elevation-z0 dark-theme dark-menu transparent-background">
            <mat-expansion-panel-header class="white-text">
              <mat-icon matListIcon style="color: #88AAFF; margin-right: 16px;" aria-hidden="true" role="img">school</mat-icon>Cursos
            </mat-expansion-panel-header>
            <mat-nav-list style="padding-top: 0px">
              <div *ngFor="let course of globals.courses">
                <a mat-list-item routerLink="/cursos/{{ course.path }}" class="white-text" *ngIf="!course.hasStarted" (click)="drawer.close()">
                  <img matListAvatar class="icon-margin menu-avatar" src="{{ course.logoURL }}" alt="...">{{ course.title }}
                </a>
                <a mat-list-item routerLink="/cursos/{{ course.path }}/contenido" class="white-text" *ngIf="course.hasStarted" (click)="drawer.close()">
                  <img matListAvatar class="icon-margin menu-avatar" src="{{ course.logoURL }}" alt="...">{{ course.title }}
                </a>
              </div>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion displayMode="flat" class="dark-theme transparent-background">
          <mat-expansion-panel class="mat-elevation-z0 dark-theme dark-menu transparent-background">
            <mat-expansion-panel-header class="white-text">
              <mat-icon matListIcon style="color: #55FF00; margin-right: 16px;" aria-hidden="true" role="img">phone_android</mat-icon>Aplicaciones
            </mat-expansion-panel-header>
            <mat-nav-list style="padding-top: 0px">
              <a mat-list-item routerLink="/apps/calculadora" class="white-text" (click)="drawer.close()">
                <img matListAvatar class="icon-margin menu-avatar" style="border-radius: 0px !important;" src="../assets/apps/nodo-electronico-calculator-icon.png" alt="...">
                Calculadora
              </a>
              <a mat-list-item routerLink="/apps/terminal-puerto-serie" class="white-text" (click)="drawer.close()">
                <img matListAvatar class="icon-margin menu-avatar" style="border-radius: 0px !important;" src="../assets/apps/nodo-electronico-serial-terminal-icon.png" alt="...">
                Terminal Puerto Serie
              </a>
              <a mat-list-item routerLink="/apps/microcontroladores" class="white-text" (click)="drawer.close()">
                <img matListAvatar class="icon-margin menu-avatar" style="border-radius: 0px !important;" src="../assets/apps/microcontrollers-icon.png" alt="...">
                Microcontroladores
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-nav-list class="transparent-background" style="padding-top: 0px">
          <a mat-list-item class="white-text main-menu-item" routerLink="/suscripcion" (click)="drawer.close()">
            <mat-icon class="icon-margin" matListIcon style="color: #FFFF00" aria-hidden="true" role="img">local_play</mat-icon>Suscripción
          </a>
        </mat-nav-list>
        <div class="bottom-element">
          <mat-nav-list class="transparent-background" style="padding-top: 0px">
            <img src="../assets/logo-nombre-blanco.png" alt="" height="42px" style="margin: 8px 24px;" *ngIf="userAuthenticated">
            <a mat-list-item (click)="drawer.close()" class="main-menu-item" style="color: #AAAAAA">
              <mat-icon class="icon-margin" matListIcon style="color: #AAAAAA" aria-hidden="true" role="img">arrow_back</mat-icon>Cerrar Menú
            </a>
          </mat-nav-list>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content #appContent [ngClass]="{ 'light-theme-background': !darkThemeEnabled, 'blur-content': (isHandset && drawer.opened) }">
      <mat-toolbar color="primary" class="top-dark-gradient dark-theme" style="z-index: 101 !important;">
        <button
          type="button"
          mat-icon-button
          (click)="drawer.toggle()"
          fxHide="true" fxShow.lt-md="true">
          <mat-icon>menu</mat-icon>
        </button>
        <img src="../assets/logo-nombre-blanco.png" alt="" height="36px" style="margin: 0px 8px" *ngIf="!drawer.opened">
        <span style="margin-left: auto;"></span>
        <button mat-button routerLink="/inicio" fxShow="true" fxHide.lt-sm="true" *ngIf="userAuthenticated">
          <mat-icon class="icon-margin" style="color: #FF8800" aria-hidden="true" role="img">view_quilt</mat-icon>Inicio
        </button>
        <button mat-button [matMenuTriggerFor]="coursesMenu" fxShow="true" fxHide.lt-md="true">
          <mat-icon class="icon-margin" style="color: #88AAFF" aria-hidden="true" role="img">school</mat-icon>Cursos
          <mat-icon aria-hidden="true" role="img">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #coursesMenu="matMenu" class="dark-theme mainMenu" xPosition="after">
          <div *ngFor="let course of globals.courses">
            <button mat-menu-item routerLink="/cursos/{{ course.path }}" *ngIf="!course.hasStarted">
              <img src="{{ course.logoURL }}" alt="{{ course.title }}" class="courses-menu">
              <span style="vertical-align: top;">{{ course.title }}</span>
            </button>
            <button mat-menu-item routerLink="/cursos/{{ course.path }}/contenido" *ngIf="course.hasStarted">
              <img src="{{ course.logoURL }}" alt="{{ course.title }}" class="courses-menu">
              <span style="vertical-align: top;">{{ course.title }}</span>
            </button>
          </div>
        </mat-menu>
        <button mat-button [matMenuTriggerFor]="appsMenu" fxShow="true" fxHide.lt-md="true">
          <mat-icon class="icon-margin" style="color: #55FF00" aria-hidden="true" role="img">phone_android</mat-icon>Aplicaciones
          <mat-icon aria-hidden="true" role="img">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #appsMenu="matMenu" class="dark-theme mainMenu" xPosition="after">
          <a mat-menu-item routerLink="/apps/calculadora">
            <img class="courses-menu" src="../assets/apps/nodo-electronico-calculator-icon.png" alt="Calculadora">
            <span style="vertical-align: top;">
              Calculadora
            </span>
          </a>
          <a mat-menu-item routerLink="/apps/microcontroladores">
            <img class="courses-menu" src="../assets/apps/microcontrollers-icon.png" alt="Microcontroladores">
            <span style="vertical-align: top;">
              Microcontroladores
            </span>
          </a>
        </mat-menu>
        <button mat-button routerLink="/suscripcion" fxShow="true" fxHide.lt-sm="true">
          <mat-icon class="icon-margin" style="color: #FFFF00" aria-hidden="true" role="img">local_play</mat-icon>Suscripción
        </button>
        <button mat-button routerLink="/acceso" fxShow="true" fxHide.lt-md="true" *ngIf="!userAuthenticated">
          <mat-icon class="icon-margin" style="color: #0088FF" aria-hidden="true" role="img">perm_identity</mat-icon>Acceder
        </button>
        <button mat-button routerLink="/registro" fxShow="true" fxHide.lt-md="true" *ngIf="!userAuthenticated">
          <mat-icon class="icon-margin" style="color: #88FFAA" aria-hidden="true" role="img">assignment_ind</mat-icon>Registrarse
        </button>
        <button mat-icon-button [matMenuTriggerFor]="mainMenu" aria-label="Menú" *ngIf="userAuthenticated && userPhotoURL" fxShow fxHide.gt-xs="true">
          <img matListAvatar class="user-avatar" src="{{ userPhotoURL }}" alt="Usuario">
        </button>
        <button mat-button [matMenuTriggerFor]="mainMenu" aria-label="Menú" *ngIf="userAuthenticated && userPhotoURL" fxShow fxHide.xs="true">
          <img matListAvatar class="icon-margin user-avatar" src="{{ userPhotoURL }}" alt="Usuario">
          <span fxShow="true" fxHide.lt-md="true">
            {{ userName }}
          </span>
          <mat-icon aria-hidden="true" role="img">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #mainMenu="matMenu" class="dark-theme mainMenu" xPosition="before">
          <button mat-menu-item routerLink="/mi-cuenta">
            <mat-icon style="color: #00FFFF" aria-hidden="true" role="img">account_circle</mat-icon>
            <span>Mi Cuenta</span>
          </button>
          <button mat-menu-item (click)="darkMode(true)" *ngIf="!globals.darkTheme">
            <mat-icon style="color: #FFFFAA" aria-hidden="true" role="img">brightness_2</mat-icon>
            <span>Modo Oscuro</span>
          </button>
          <button mat-menu-item (click)="darkMode(false)" *ngIf="globals.darkTheme">
            <mat-icon style="color: #FFFF55" aria-hidden="true" role="img">wb_sunny</mat-icon>
            <span>Modo Claro</span>
          </button>
          <button mat-menu-item (click)="logoutUser()">
            <mat-icon style="color: #FF5555" aria-hidden="true" role="img">highlight_off</mat-icon>
            <span>Cerrar Sesión</span>
          </button>
        </mat-menu>
      </mat-toolbar>
      <router-outlet></router-outlet>
      <div class="app-footer">
        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="left center" fxLayoutAlign.lt-sm="center center">
          <div fxFlex="50%">
            <img src="../assets/logo-nombre-blanco.png" alt="" height="48px" style="margin: 16px 32px;">
          </div>
          <div fxFlex="50%" class="container text-right">
            <a routerLink="/aviso-de-privacidad" mat-button style="color: #AAAAAA;">
              <mat-icon class="icon-margin" aria-hidden="true" role="img">lock</mat-icon>
              <span>Aviso de Privacidad</span>
            </a>
            <a routerLink="/terminos-y-condiciones" mat-button style="color: #AAAAAA;">
              <mat-icon class="icon-margin" aria-hidden="true" role="img">menu_book</mat-icon>
              <span>Términos y Condiciones</span>
            </a>
          </div>
        </div>
        <p class="text-right mat-caption container" style="color: #888888">Tu punto de encuentro con la electrónica © Nodo Electrónico 2022</p>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>