<section>
  <h1 mat-dialog-title class="subtitle text-center">Activación de Código</h1>
  <div mat-dialog-content>
    <div *ngIf="coupon.sponsor" >
      <p class="text-center" *ngIf="coupon.sponsor">
        <img src="{{ coupon.sponsor.logo }}" alt="Logo de Patrocinador" class="sponsor-logo">
      </p>
      <p class="text text-center">
        ¡Felicidades! Has obtenido un acceso gratuito a la plataforma de parte de <strong>{{ coupon.sponsor.name }}</strong> obtendrás <strong>{{ coupon.days }} días</strong> de acceso completo a todos los cursos y aplicaciones.
      </p>

    </div>
    <div *ngIf="!coupon.sponsor">
      <p class="text text-center">
        ¡Gracias por tu compra! Obtendrás <strong style="color: #5588FF">{{ coupon.days }} días</strong> de acceso completo a la plataforma
      </p>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button mat-flat-button color="accent" style="margin-left: auto;" mat-dialog-close="true">Canjear Cupón</button>
  </div>
</section>