<mat-tab-group color="accent" class="course-mat-tab" animationDuration="0ms">
  <mat-tab>
    <ng-template mat-tab-label style="color: #EEEEEE !important;">
      <mat-icon class="icon-margin">book</mat-icon>
      Contenido
    </ng-template>
    <mat-spinner *ngIf="downloading" style="margin:20% auto; margin-bottom: 100%;"></mat-spinner>
    <div *ngIf="!downloading" [@fadeAnimation]="'in'">
      <br>
      <div class="main-block mat-elevation-z8" [style]="sanitizer.bypassSecurityTrustStyle('background-image: linear-gradient(' + course.heroBackgroundStartColor + ',' + course.heroBackgroundEndColor + ');')">
        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="center center">
          <div fxFlex="50%" class="presentation-text">
            <br>
            <p style="display: flex; align-items: center; flex-flow: wrap; margin: 0px;">
              <img src="{{ course?.logoURL }}" alt="" srcset="" width="80px" height="80px">
              <span class="title course-title" [style.color]="secureTitleColor">
                {{ course.title }}
              </span>
            </p>
            <p class="text" [style.color]="secureDescriptionColor" style="margin-bottom: 0px;">{{ course.description }}</p>
            <div fxLayout="row wrap" fxLayoutAlign="center center">
              <div style="width: 120px; display: inline-block; height: 120px;">
                <div class="progress-wrapper">
                  <round-progress
                    [current]="userProgress"
                    max="100"
                    [color]="'url(#gradient)'"
                    [background]="'#eaeaea'"
                    [radius]="50"
                    [stroke]="15"
                    [semicircle]="false"
                    [rounded]="true"
                    [clockwise]="true"
                    [responsive]="false"
                    [duration]="800"
                    [animation]="'easeOutCubic'"
                    [animationDelay]="0">
                  </round-progress>
                </div>
              </div>
              <div style="width: calc(100% - 120px);display: inline-block;">
                <p class="text" [style.color]="secureDescriptionColor" *ngIf="userProgress" style="margin-top: 0px; margin-bottom: 4px;"><strong [style.color]="secureDescriptionColor">Progreso:</strong> {{ userProgress | number:'1.0-2'}}%</p>
                <p class="text" [style.color]="secureDescriptionColor" *ngIf="lessonsCompletedQuantity" style="margin-top: 0px; margin-bottom: 0px;"><strong [style.color]="secureDescriptionColor">Lecciones Completadas:</strong> {{ lessonsCompletedQuantity }} de {{ lessonsQuantity }}</p>
              </div>
            </div>
            <br>
            <button mat-flat-button color="warn" routerLink="/cursos/{{ courseID }}/leccion/{{ course.tableOfContents[0].lessons[0].path }}" *ngIf="!userLastLessonPath && !downloading && course.tableOfContents"><span style="color: white;">Ver Primera Lección</span></button>
            <button mat-flat-button color="warn" routerLink="/cursos/{{ courseID }}/leccion/{{ userLastLessonPath }}" *ngIf="userLastLessonPath && !downloading" style="max-width: 310px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><span style="color: white;">Continuar con: {{ userLastLessonTitle }}</span></button>
            <br><br>
          </div>
          <div fxFlex="50%" style="padding: 16px;">
            <img src="{{ course.heroImageURL }}" alt="" width="100%" height="auto">
          </div>
        </div>
      </div>
      <br>
      <div class="block-container">
        <h1 class="title" style="padding: 0px 16px;">Contenido</h1>
        <div *ngFor="let section of course.tableOfContents; index as i">
          <h1 class="subtitle" style="padding: 0px 16px; margin-bottom: 0px;">Sección {{i + 1}}: {{ section.title }}</h1>
          <carousel [cellWidth]="250">
            <div class="carousel-cell" *ngFor="let lesson of section.lessons">
              <div *ngIf="lesson.completed">
                <img src="{{ lesson.thumbnail?.url }}" class="cover-image lesson-completed" (click)="viewLessonDetails(lesson);">
                <p class="completed-text">Completado</p>
              </div>  
              <img src="{{ lesson.thumbnail?.url }}" *ngIf="!lesson.completed" class="cover-image" (click)="viewLessonDetails(lesson)">
            </div>
          </carousel>
          <br>
        </div>
        <h2 class="subtitle" *ngIf="course.newContentMessage">{{ course.newContentMessage }}</h2>
      </div>
    </div>
  </mat-tab>
  <mat-tab *ngIf="course?.achievements?.sections?.length > 0">
    <ng-template mat-tab-label style="color: #EEEEEE !important;">
      <mat-icon class="icon-margin">stars</mat-icon>
      Logros
    </ng-template>
    <button mat-fab color="accent" class="fab-menu" matTooltip="Guardar Progreso" matTooltipPosition="left" (click)="saveUserAchievements()">
      <mat-icon>save</mat-icon>
    </button>
    <div class="block-container">
      <h1 class="title text-center">Métricas</h1>
      <p class="text">Marca los objetivos que hayas logrado, varios de estos objetivos lograrás obtenerlos a través de este curso pero no se limita a lo aprendido aquí, el objetivo es tener una medición tu conocimiento y experiencia.</p>
      <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="center top">
        <div fxFlex="25%" fxFlex.sm="50%" fxFlex.xs="100%" *ngFor="let section of course.achievements?.sections">
          <div style="width: 110px; height: 110px; margin: auto;">
            <div class="progress-wrapper">
              <round-progress
                [current]="(section.total/section.achievements.length)*100"
                max="100"
                [color]="'url(#gradient)'"
                [background]="'#dddddd'"
                [radius]="50"
                [stroke]="15"
                [semicircle]="false"
                [rounded]="true"
                [clockwise]="true"
                [responsive]="false"
                [duration]="800"
                [animation]="'easeOutCubic'"
                [animationDelay]="0">
              </round-progress>
            </div>
          </div>
          <h2 class="text text-center" style="margin-top: 0px;">
            <strong>{{ section.name }}</strong><br>{{ section.total}}/{{section.achievements.length}}
          </h2>
        </div>
      </div>
      <br><br>
      <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="center top">
        <div fxFlex="50%" fxFlex.lt-md="100%" *ngFor="let section of course.achievements?.sections;">
          <div>
            <h1 class="subtitle">{{ section.name }}</h1>
            <mat-list>
              <mat-list-item *ngFor="let achievement of section.achievements" matRipple class="achievement" (click)="achievement.accomplished = !achievement.accomplished; calculateMetrics()">
                <mat-icon class="padding-left check-icon" matListIcon *ngIf="!achievement.accomplished">radio_button_unchecked</mat-icon>
                <mat-icon class="padding-left check-icon achievement-checked" matListIcon *ngIf="achievement.accomplished">stars</mat-icon>
                <span class="text">{{ achievement.text }}</span>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab *ngIf="requirementsAvailable">
    <ng-template mat-tab-label style="color: #EEEEEE !important;">
      <mat-icon class="icon-margin">memory</mat-icon>
      Materiales y Requerimientos
    </ng-template>
    <div fxLayout="row wrap" class="block-container">
      <div fxFlex="100%">
        <div *ngFor="let block of requirements?.content">
          <h2 *ngIf="block.type == 'subtitle'" class="subtitle">{{ block.text }}</h2>
          <p *ngIf="block.type == 'plainText'" class="text">{{ block.text }}</p>
          <div *ngIf="block.type == 'HTMLText'" [innerHTML]="block.text" class="text"></div>
          <div class="text-center"><a mat-stroked-button *ngIf="block.type == 'link'" href="{{ block.url }}" target="_blank">{{ block.text }}</a></div>
          <p *ngIf="block.type == 'image'" class="text-center">
            <img src="{{ block.url }}" alt="{{ block.fileName }}" style="border-radius: 8px;" class="mat-elevation-z16" [ngStyle]="{'width': block.width}">
          </p>
          <mat-list *ngIf="block.type == 'bom'">
            <mat-list-item matRipple *ngFor="let component of block.components" (click)="viewElectronicComponent(component)" class="electronic-component">
              <img matListAvatar src="{{ component.thumbnail.url }}" alt="...">
              <p matLine class="title" style="font-size: 18px !important; line-height: 1rem !important; margin-top: 6px;">
                <strong>{{ component.name }}</strong>
              </p>
              <p matLine class="text" style="opacity: 0.8;">
                <strong>{{ component.quantity }} pieza<span *ngIf="component.quantity > 1">s</span></strong>
              </p>
              <p matLine class="text" style="opacity: 0.6;">
                {{ component.description }}
              </p>
            </mat-list-item>
          </mat-list>
          <div *ngIf="block.type == 'latex'">
            <ng-katex [equation]="block.code" [options]="latexOptions"></ng-katex>
          </div>
          <textarea highlight-js [options]="{}" lang="arduino" *ngIf="block.type == 'hl-code'">
            {{ block.code }}
          </textarea>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
<svg>
  <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
    <stop offset="5%" stop-color="red" />
    <stop offset="95%" stop-color="gold" />
  </linearGradient>
</svg>