import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from "@angular/fire/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Globals } from '../app-globals';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ElectronicComponentViewerDialog } from '../electronic-component-viewer-dialog/electronic-component-viewer-dialog';
import { MatDialog } from '@angular/material/dialog';
import { LessonDetailsDialog } from './lesson-details-dialog/lesson-details-dialog';

@Component({
  selector: 'app-course-content',
  templateUrl: './course-content.component.html',
  styleUrls: ['./course-content.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(300)
      ]),
      transition(':leave',
        animate(300, style({opacity: 0})))
    ])
  ]
})
export class CourseContentComponent implements OnInit {
  public course: any;
  public courseID: string = '';
  public userAuthenticated: boolean = false;
  public courseImage: string = '';
  public courseTitleColor: string = '';
  public courseDescriptionColor: string = '';
  public secureTitleColor: SafeStyle;
  public secureDescriptionColor: SafeStyle;
  public coursePrice: number;
  public demoLessonPath: string;

  public courseContent: { sectionTitle: string, lessons: string[] }[];
  public userLastLessonPath: string;
  public userLastLessonTitle: string;
  public downloading: boolean = true;

  public lessonsCompleted: any[] = [];
  public userProgress: number;
  public lessonsCompletedQuantity: number;
  public lessonsQuantity: number;
  public achievementsSections: any[] = [];
  public userAchievements: any[] = [];
  
  public requirementsAvailable: boolean = false;
  public requirements: any;

  public tempImages: any[] = [];

  constructor(
    public sanitizer: DomSanitizer,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public firestore: AngularFirestore,
    public route: ActivatedRoute,
    public router: Router,
    public globals: Globals ) { }

  ngOnInit(): void {
    this.globals.userObservable.subscribe(() => {
      let now = Date.now();
      if(this.globals.userSubscription < now || this.globals.userSubscription == null) {
        this.snackBar.open('Lo sentimos, tu suscripción a terminado 😢', 'OK', { duration: 10000, panelClass: 'error-snack-bar', horizontalPosition: 'center', verticalPosition: 'top' });
        this.router.navigateByUrl('suscripcion');
      }
    });

    this.route.paramMap.subscribe((params) => {
      this.downloading = true;
      this.course = null;
      this.demoLessonPath = '';
      this.courseImage = '';
      this.courseContent = [];
      this.courseTitleColor = '';
      this.courseDescriptionColor = '';
      this.secureTitleColor = null;
      this.secureDescriptionColor = null;
      this.userLastLessonPath = '';
      this.userLastLessonTitle = '';

      this.courseID = params.get('courseID');
      this.firestore.collection('courses', ref => ref.where('routeID','==',this.courseID).limit(1)).valueChanges({ idField: 'id'}).subscribe((data: any)=> {
        let course = data.pop();
        this.course = course;
        this.demoLessonPath = course.demoLessonPath;
        this.courseImage = course.heroImageURL;
        this.courseContent = course.content;
        this.courseTitleColor = course.heroTitleColor;
        this.courseDescriptionColor = course.heroDescriptionColor;
        this.secureTitleColor = this.sanitizer.bypassSecurityTrustStyle(this.courseTitleColor);
        this.secureDescriptionColor = this.sanitizer.bypassSecurityTrustStyle(this.courseDescriptionColor);
        this.coursePrice = course.price;

        this.course.tableOfContents.forEach(section => {
          section.lessons.forEach(lesson => {
            this.tempImages.push({
              path: lesson.thumbnail.url
            });
          });
        });

        if(this.course.achievements) {
          this.course.achievements.sections.forEach((achievementsSection) => {
            achievementsSection.achievements.forEach((achievement) => {
              achievement.accomplished = false;
            });
          });
        }
        this.globals.userObservable.subscribe(() => {
          this.firestore.collection('courses').doc(this.course.id).collection('/lessons', ref => ref.where('path', '==', 'requerimientos')).valueChanges().subscribe((requirementsData) => {
            if(requirementsData.length > 0) {
              this.requirementsAvailable = true;
              this.requirements = requirementsData[0];
            }
            else {
              this.requirementsAvailable = false;
              this.requirements = [];
            }
          });
        });

        this.globals.userObservable.subscribe(async () => {
          if(this.globals.userUID) {
            let userCourseDoc = await this.firestore.collection('users').doc(this.globals.userUID).collection('courses').doc(this.course.id).ref.get();
            if(userCourseDoc.exists) {
              this.userLastLessonPath = userCourseDoc.data().lastLesson.path;
              this.userLastLessonTitle = userCourseDoc.data().lastLesson.title;
              this.lessonsCompleted = userCourseDoc.data().lessonsCompleted;
              this.userAchievements = userCourseDoc.data().achievements? userCourseDoc.data().achievements : [];
              if(this.lessonsCompleted) {
                let lessonsQuantity: number = 0;
                let lessonsCompletedQuantity: number = 0;
                for (let sectionIndex = 0; sectionIndex < this.course.tableOfContents.length; sectionIndex++) {
                  let section = this.course.tableOfContents[sectionIndex];
                  for (let lessonsIndex = 0; lessonsIndex < section.lessons.length; lessonsIndex++) {
                    lessonsQuantity++;
                    let lesson = section.lessons[lessonsIndex];
                    for (let index = 0; index < this.lessonsCompleted.length; index++) {
                      if(lesson.path == this.lessonsCompleted[index].path) {
                        lessonsCompletedQuantity++;
                        this.course.tableOfContents[sectionIndex].lessons[lessonsIndex].completed = true;
                      }
                    }
                  }
                }
                this.userProgress = (lessonsCompletedQuantity/lessonsQuantity)*100;
                this.lessonsCompletedQuantity = lessonsCompletedQuantity;
                this.lessonsQuantity = lessonsQuantity;
              }
              if(this.course.achievements) {
                this.course.achievements.sections.forEach((achievementsSection) => {
                  achievementsSection.achievements.forEach((achievement) => {
                    this.userAchievements.forEach((userAchievement) => {
                      if(userAchievement == achievement.code) {
                        achievement.accomplished = true;
                      }
                    });
                  });
                });
                this.calculateMetrics();
              }
            }
            this.downloading = false;
          }
          else {
            this.downloading = false;
          }
        })
      });
    });
  }

  calculateMetrics() {
    this.course.achievements.sections.forEach((achievementsSection) => {
      achievementsSection.total = 0;
      achievementsSection.achievements.forEach((achievement) => {
        if(achievement.accomplished) {
          achievementsSection.total++;
        }
      });
    });
  }

  async saveUserAchievements() {
    let userAchievements = [];
    this.course.achievements.sections.forEach((achievementsSection) => {
      achievementsSection.achievements.forEach((achievement) => {
        if(achievement.accomplished) {
          userAchievements.push(achievement.code);
        }
      });
    });
    await this.firestore.collection('users').doc(this.globals.userUID).collection('courses').doc(this.course.id).update({
      achievements: userAchievements
    });
    this.snackBar.open('Logros Guardados', 'OK', { duration: 10000, panelClass: 'success-snack-bar', horizontalPosition: 'right', verticalPosition: 'top' });
  }

  viewLessonDetails(lesson) {
    this.dialog.open(LessonDetailsDialog, {
      autoFocus: false,
      data: lesson,
      width: '720px',
      panelClass: ['lesson-details', 'dark-theme']
    }).afterClosed().subscribe((open) => {
      if(open) {
        this.router.navigateByUrl('/cursos/' + this.courseID + '/leccion/' + lesson.path);
      }
    })
    //
  }

  viewElectronicComponent(component) {
    let dialogClasses: string[];
    if(this.globals.darkTheme) {
      dialogClasses = ['dark-dialog', 'dark-theme'];
    }
    else {
      dialogClasses = ['light-dialog']
    }
    this.dialog.open(ElectronicComponentViewerDialog, {
      autoFocus: false,
      data: component,
      width: '960px',
      maxWidth: '95vw',
      panelClass: dialogClasses
    });
  }
}
