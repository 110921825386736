<div fxLayout="row wrap" fxLayoutAlign="space-between start" class="store-container" [@fadeAnimation]="'in'">
  <div fxFlex="62%" fxFlex.lt-md="100%">
    <h1 class="text-center title" style="margin-bottom: 12px;">Productos</h1>
    <div fxLayout="row wrap" fxLayoutAlign="start start" >
      <div fxFlex="50%" fxFlex.xs="100%" class="text-center" style="margin: 0px auto;" *ngFor="let product of products">
        <mat-card class="mat-elevation-z0 product-card">
          <div class="product-image" [style.background-image]="sanitizer.bypassSecurityTrustStyle('url('+ product.thumbnailImage.url +')')" matRipple routerLink="/tienda/{{ product.path }}"></div>
          <mat-card-content style="padding: 16px 8px;">
            <h1 class="product-name text"><strong>{{ product.name }}</strong></h1>
            <h3 class="product-price text">${{ product.price | number:'1.2-2' }} MXN</h3>
            <button mat-flat-button color="accent" (click)="addToCart(product)">Agregar al Carrito</button>
          </mat-card-content>
        </mat-card>
        <br>
      </div>
    </div>
  </div>
  <div fxFlex="34%" fxFlex.lt-md="100%">
    <div style="width: 100%;">
      <h1 class="text-center title" style="margin-bottom: 12px;">Carrito</h1>
      <mat-card class="mat-elevation-z0" style="border-radius: 8px;">
        <div style="overflow-y: auto; height: calc(100vh - 348px);">
          <table style="width:100%;" *ngIf="globals.cart?.length > 0">
            <thead style="position: sticky; top: 0;">
              <tr *ngIf="globals.cart?.length > 0" >
                <th style="position: sticky; top: 0; width: 50px;"></th>
                <th style="text-align: left; position: sticky; top: 0;"><h3 style="margin-bottom: 4px; margin-top: 0px;">Producto</h3></th>
                <th style="text-align: right; width: 64px; position: sticky; top: 0;"><h3 style="margin-bottom: 4px; margin-top: 0px;" matTooltip="Precio Unitario">P/U</h3></th>
              </tr>
            </thead>
            <mat-divider></mat-divider>
            <tr *ngFor="let product of globals.cart; index as i">
              <td> <img src="{{ product.thumbnailImage.url }}" alt="" style="width: 42px; height: 42px; border-radius: 4px;"> </td>
              <td style="text-align: left;"><h3 class="title product-name">{{ product.name }}</h3> <p style="margin: 4px 16px 4px 0px">Cantidad: {{ product.quantity }} <button mat-button color="warn" style="height: 28px; line-height: 28px;" (click)="removeProduct(i)" matTooltip="Quitar producto del carrito">Quitar</button></p></td>
              <td style="text-align: right;"><p style="margin: 4px 0px;">${{ product.price | number:'1.2-2' }}</p></td>
            </tr>
          </table>
          <h3 *ngIf="globals.cart?.length <= 0" class="text-center" style="color: #888888">No hay productos en el carrito</h3>
        </div>
        <br>
        <mat-divider></mat-divider>
        <table style="width:100%; margin-top: 8px;">
          <tr>
            <td style="text-align: right;"></td>
            <td style="text-align: right;"><h3 style="margin: 0px;" class="text">Envío incluído</h3></td>
          </tr>
          <tr>
            <td style="text-align: left;"><h2 style="margin-top: 0px; margin-bottom: 8px;" class="subtitle">Total</h2></td>
            <td style="text-align: right;"><h2 style="margin-top: 0px; margin-bottom: 8px;" class="subtitle">${{ total | number:'1.2-2' }} MXN</h2></td>
          </tr>
        </table>
        <button mat-flat-button color="accent" style="width: 100%; margin-bottom: 8px;" [disabled]="globals.cart?.length == 0" routerLink="/pago">Generar Pedido</button>
      </mat-card>
    </div>
  </div>
</div>
