import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { NoticeOfPrivacyComponent } from './notice-of-privacy/notice-of-privacy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { AppDetailsComponent } from './app-details/app-details.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CourseSalePageComponent } from './course-sale-page/course-sale-page.component';
import { CourseContentComponent } from './course-content/course-content.component';
import { CourseLessonComponent } from './course-lesson/course-lesson.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { MessagesComponent } from './messages/messages.component';
import { StoreComponent } from './store/store.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { PaymentComponent } from './payment/payment.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["acceso"]);

const routes: Routes = [
  { path: 'acceso', component: LoginComponent },
  { path: 'acceso/:redirectRoute', component: LoginComponent },
  { path: 'registro', component: SignUpComponent },
  { path: 'registro/:redirectRoute', component: SignUpComponent },
  { path: 'mi-cuenta', component: UserSettingsComponent },
  { path: 'aviso-de-privacidad', component: NoticeOfPrivacyComponent },
  { path: 'terminos-y-condiciones', component: TermsAndConditionsComponent },
  { path: 'inicio', component: DashboardComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'apps/:appID', component: AppDetailsComponent },
  { path: 'cursos/:courseID', component: CourseSalePageComponent },
  { path: 'cursos/:courseID/contenido', component: CourseContentComponent },
  { path: 'cursos/:courseID/leccion/:lessonID', component: CourseLessonComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'suscripcion', component: SubscribeComponent },
  { path: 'suscribirse', redirectTo: '/suscripcion', pathMatch: 'full' },
  { path: 'tienda', redirectTo: 'inicio' },
  { path: 'tienda/:productID', redirectTo: 'inicio' },
  { path: 'pago', component: PaymentComponent },
  { path: 'suscripcion/codigo/:accessCode', component: SubscribeComponent },
  { path: 'mensajes', component: MessagesComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: '', redirectTo: '/inicio', pathMatch: 'full' },
  { path: '**', redirectTo: '/inicio', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {  scrollOffset: [0, 0], scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
