<div fxLayout="row wrap" fxLayoutAlign="space-between start" class="store-container" [@fadeAnimation]="'in'">
  <div fxFlex="62%" fxFlex.lt-md="100%">
    <h1 class="title" style="margin-bottom: 12px;">{{ product?.name }}</h1>
    <div fxLayout="row wrap" fxLayout.xs="column">
      <div fxFlex="256px" fxFlex.lt-md="100%">
        <p class="text-center" style="margin: 0px;">
          <img src="{{ product?.thumbnailImage?.url }}" alt="" width="232px" style="border-radius: 16px; margin-right: 24px">
        </p>
      </div>
      <div fxFlex="calc(100%-256px)" fxFlex.lt-md="100%">
        <p class="text" style="margin-bottom: 4px !important; margin-top: 0px;">{{ product?.description }}</p>
        <p class="text" style="margin-top: 0px; color: #888888"><strong style="color: #888888">SKU: </strong>{{ product?.sku }}</p>
        <h3 class="subtitle"><strong>${{ product?.price | number:'1.2-2' }} MXN</strong></h3>
        <button mat-flat-button color="accent" (click)="addToCart(product)">Agregar al Carrito</button>
      </div>
    </div>
    <br>
    <div style="width: 100%;">
      <div *ngFor="let block of product?.content">
        <h2 *ngIf="block.type == 'subtitle'" class="subtitle" style="margin-bottom: 4px;">{{ block.text }}</h2>
        <p *ngIf="block.type == 'plainText'" class="text">{{ block.text }}</p>
        <div *ngIf="block.type == 'HTMLText'" [innerHTML]="block.text" class="text"></div>
        <div class="text-center"><a mat-stroked-button *ngIf="block.type == 'link'" href="{{ block.url }}" target="_blank">{{ block.text }}</a></div>
        <p *ngIf="block.type == 'image'" class="text-center">
          <img src="{{ block.url }}" alt="{{ block.fileName }}" style="border-radius: 8px;" class="mat-elevation-z16" [ngStyle]="{'width': block.width}">
        </p>
        <div style="margin-bottom: 24px !important;" *ngIf="block.type == 'bom'">
          <mat-list>
            <mat-list-item matRipple *ngFor="let component of block.components" (click)="viewElectronicComponent(component)" class="electronic-component">
              <img matListAvatar src="{{ component.thumbnail.url }}" alt="...">
              <p matLine class="text" style="line-height: 1.2rem; margin-top: 6px; font-size: 16px !important;">
                <strong>{{ component.name }}</strong>
              </p>
              <p matLine class="text" style="line-height: 1.2rem; opacity: 0.7; font-size: 16px !important;">
                {{ component.quantity }} pieza<span *ngIf="component.quantity > 1">s</span>
              </p>
            </mat-list-item>
          </mat-list>
        </div>
        <div *ngIf="block.type == 'latex'">
          <ng-katex [equation]="block.code" [options]="latexOptions"></ng-katex>
        </div>
        <textarea highlight-js [options]="{ languages: ['arduino'] }" *ngIf="block.type == 'hl-code'">
          {{ block.code }}
        </textarea>
      </div>
    </div>
  </div>
  <div fxFlex="34%" fxFlex.lt-md="100%">
    <div style="width: 100%; position: sticky;">
      <h1 class="text-center title" style="margin-bottom: 12px;">Carrito</h1>
      <mat-card class="mat-elevation-z0" style="border-radius: 8px;">
        <div style="overflow-y: auto; height: calc(100vh - 348px);">
          <table style="width:100%;" *ngIf="globals.cart?.length > 0">
            <thead style="position: sticky; top: 0;">
              <tr *ngIf="globals.cart?.length > 0" >
                <th style="position: sticky; top: 0; width: 50px;"></th>
                <th style="text-align: left; position: sticky; top: 0;"><h3 style="margin-bottom: 4px; margin-top: 0px;">Producto</h3></th>
                <th style="text-align: right; width: 64px; position: sticky; top: 0;"><h3 style="margin-bottom: 4px; margin-top: 0px;" matTooltip="Precio Unitario">P/U</h3></th>
              </tr>
            </thead>
            <mat-divider></mat-divider>
            <tr *ngFor="let product of globals.cart; index as i">
              <td> <img src="{{ product.thumbnailImage.url }}" alt="" style="width: 42px; height: 42px; border-radius: 4px;"> </td>
              <td style="text-align: left;"><h3 class="title product-name">{{ product.name }}</h3> <p style="margin: 4px 16px 4px 0px">Cantidad: {{ product.quantity }} <button mat-button color="warn" style="height: 28px; line-height: 28px;" (click)="removeProduct(i)" matTooltip="Quitar producto del carrito">Quitar</button></p></td>
              <td style="text-align: right;"><p style="margin: 4px 0px;">${{ product.price | number:'1.2-2' }}</p></td>
            </tr>
          </table>
          <h3 *ngIf="globals.cart?.length <= 0" class="text-center" style="color: #888888">No hay productos en el carrito</h3>
        </div>
        <br>
        <mat-divider></mat-divider>
        <table style="width:100%; margin-top: 8px;">
          <tr>
            <td style="text-align: right;"></td>
            <td style="text-align: right;"><h3 style="margin: 0px;" class="text">Envío incluído</h3></td>
          </tr>
          <tr>
            <td style="text-align: left;"><h2 style="margin-top: 0px; margin-bottom: 8px;" class="subtitle">Total</h2></td>
            <td style="text-align: right;"><h2 style="margin-top: 0px; margin-bottom: 8px;" class="subtitle">${{ total | number:'1.2-2' }} MXN</h2></td>
          </tr>
        </table>
        <button mat-flat-button color="accent" style="width: 100%; margin-bottom: 8px;" [disabled]="globals.cart?.length == 0" routerLink="/pago">Generar Pedido</button>
      </mat-card>
    </div>
  </div>
</div>