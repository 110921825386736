<mat-spinner *ngIf="downloading" style="margin:20% auto; margin-bottom: 100%;"></mat-spinner>
<div *ngIf="!downloading" [@fadeAnimation]="'in'">
  <br>
  <div class="main-block mat-elevation-z8" [style]="sanitizer.bypassSecurityTrustStyle('background-image: linear-gradient(' + course.heroBackgroundStartColor + ',' + course.heroBackgroundEndColor + ');')">
    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="center center">
      <div fxFlex="50%" class="presentation-text">
        <p style="display: flex; align-items: center; flex-flow: wrap; margin: 0px;">
          <img src="{{ course?.logoURL }}" alt="" srcset="" width="80px" height="80px">
          <span class="title course-title" [style.color]="secureTitleColor">
            {{ course.title }}
          </span>
        </p>
        <br>
        <p class="text" [style.color]="secureDescriptionColor">{{ course.description }}</p>
        <p>
          <button mat-stroked-button routerLink="/cursos/{{ courseID }}/contenido" style="color: #FFFFFF; margin-right: 16px;" *ngIf="userLastLessonPath && !downloading">Ver Contenido</button>
          <button mat-flat-button color="warn" routerLink="/cursos/{{ courseID }}/contenido" *ngIf="!userLastLessonPath && !downloading && course.tableOfContents && globals.userUID && (globals.userSubscription > now) && (globals.userSubscription != null)">Iniciar Curso</button>
          <button mat-flat-button color="warn" routerLink="/suscripcion" *ngIf="!userLastLessonPath && !downloading && course.tableOfContents && globals.userUID && ((globals.userSubscription < now) || (globals.userSubscription == null))">Suscribirse</button>
          <button mat-stroked-button routerLink="/acceso" *ngIf="!globals.userUID" [style.color]="secureDescriptionColor">Iniciar Sesión</button>
          <button mat-flat-button color="warn" routerLink="/registro" *ngIf="!globals.userUID" style="margin-left: 8px;">Registrarse</button>
        </p>
        <p class="subscription-info" *ngIf="!globals.userUID" [style.color]="secureDescriptionColor">Es necesario iniciar sesión y tener una suscripción activa para acceder al curso</p>
        <p class="subscription-info" *ngIf="!userLastLessonPath && !downloading && course.tableOfContents && globals.userUID && ((globals.userSubscription < now) || (globals.userSubscription == null))" [style.color]="secureDescriptionColor">Es necesario tener una suscripción activa para acceder al curso</p>
        <p>
          <button mat-flat-button color="warn" routerLink="/cursos/{{ courseID }}/leccion/{{ userLastLessonPath }}" *ngIf="userLastLessonPath && !downloading">Continuar</button>
        </p>
      </div>
      <div fxFlex="50%" style="padding: 16px;">
        <img src="{{ course.heroImageURL }}" alt="" width="100%" height="auto">
      </div>
    </div>
  </div>
  <br>
  <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="center center" *ngIf="course.features" class="block-container">
    <div fxFlex="50%" fxFlex.xs="100%">
      <h2 class="title" style="margin-bottom: 16px; margin-left: 16px;">
        ¿Qué aprenderé?
      </h2>
      <mat-list dense>
        <mat-list-item class="text" *ngFor="let feature of course.features">
          <mat-icon class="icon-margin" matListIcon [ngStyle]="{ 'color': feature.color }">{{ feature.icon }}</mat-icon>
          {{ feature.text }}
        </mat-list-item>
      </mat-list>
      <br>
    </div>
    <div fxFlex="50%" fxFlex.xs="100%" style="padding: 16px;">
      <img src="{{ course.featuredImage1 }}" alt="" width="100%" height="auto" style="border-radius: 8px;">
    </div>
  </div>
  <br>
  <div class="video-block" *ngIf="secureCourseVideo">
    <div class="video-container">
      <div class="lesson-video-wrapper">
        <iframe [src]="secureCourseVideo" class="lesson-video" width="100%" height="auto" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
      </div>
    </div>
  </div>
  <div class="block-container" *ngIf="course.content" style="padding: 16px">
    <h1 class="title text-center">Contenido del Curso</h1>
    <mat-accordion class="full-width" displayMode="flat">
      <mat-expansion-panel *ngFor="let content of course.content" class="mat-elevation-z0">
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title class="text">
            <strong>{{ content.sectionTitle }}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li class="text" *ngFor="let lesson of content.lessons">
            {{ lesson }}
          </li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <h2 class="title text-center" *ngIf="course.features" style="margin-bottom: 8px;">
    Características de este curso
  </h2>
  <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="center center" *ngIf="course.features" class="block-container">
    <div fxFlex="50%">
      <img src="{{ course.sampleImageURL }}" alt="" width="100%" height="auto" style="border-radius: 8px;">
    </div>
    <div fxFlex="50%">
      <br>
      <mat-list dense>
        <mat-list-item class="text" *ngFor="let feature of course.parameters">
          <mat-icon class="icon-margin" matListIcon [ngStyle]="{ 'color': feature.color }">{{ feature.icon }}</mat-icon>
          {{ feature.text }}
        </mat-list-item>
      </mat-list>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="center center" *ngIf="course.faq" class="block-container">
    <div fxFlex="60%" fxFlex.md="70%" fxFlex.sm="80%" fxFlex.xs="100%">
      <h1 class="title text-center" style="margin-bottom: 8px;">Preguntas Frecuentes</h1>
      <div *ngFor="let faq of course.faq">
        <h2 class="subtitle">{{ faq.question }}</h2>
        <p class="text">{{ faq.answer }}</p>
      </div>
      <br>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="center center">
    <div fxFlex="60%" fxFlex.md="70%" fxFlex.sm="80%" fxFlex.xs="100%" class="container text-center">
      <p>
        <button mat-raised-button color="warn" routerLink="/cursos/{{ courseID }}/leccion/{{ course.tableOfContents[0].lessons[0].path }}" *ngIf="!userLastLessonPath && !downloading && course.tableOfContents" class="mat-elevation-z0">Iniciar</button>
        <button mat-raised-button color="warn" routerLink="/cursos/{{ courseID }}/leccion/{{ userLastLessonPath }}" *ngIf="userLastLessonPath && !downloading" class="mat-elevation-z0">Continuar</button>
      </p>
    </div>
  </div>
</div>