import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from "@angular/router";
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { UserAvatarDialog } from './user-avatar-dialog/user-avatar-dialog';
import { MatDialog } from '@angular/material/dialog';
import { OrderDetailsDialog } from "./order-details-dialog/order-details-dialog";

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {
  public userAuthenticated: boolean = false;
  public userName: string = '';
  public userMail: string = '';
  public userPhotoURL: string = '';
  public darkThemeEnabled: boolean = false;
  public userDoc: AngularFirestoreDocument<any>;
  public user: Observable<any>;
  public userOrdersCol: AngularFirestoreCollection<any>;
  public userOrders: Observable<any[]>;
  public userPhone: string;
  public userEndOfSubscription: number;
  public downloading: boolean = true;

  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    public afFirestore: AngularFirestore,
    public dialog: MatDialog ) { }

  ngOnInit() {
    this.afAuth.authState.subscribe((user) => {
      if(user != null) {
        this.userAuthenticated = true;
        this.userDoc = this.afFirestore.doc<any>('users/' + user.uid);
        this.user = this.userDoc.valueChanges();
        this.user.subscribe((userData: any) => {
          this.userName = userData.name;
          this.userMail = userData.mail;
          this.userPhone = userData.phone;
          this.userPhotoURL = userData.photoURL;
          this.userEndOfSubscription = userData.endOfSubscription;
          this.downloading = false;
        });

        this.userOrdersCol = this.afFirestore.collection('orders', ref => ref.where('userUID','==',user.uid).orderBy('date','desc').limit(10));
        this.userOrders = this.userOrdersCol.valueChanges();
      }
      else {
        this.userAuthenticated = false;
      }
    });
  }

  changeUserAvatar() {
    this.dialog.open(UserAvatarDialog, {
      width: '600px',
      autoFocus: false
    }).afterClosed().subscribe(result => {
      if(result) {
        this.userDoc.update({ photoURL: result });
      }
    });
  }
  
  showDetails(order) {
    this.dialog.open(OrderDetailsDialog, {
      width: '600px',
      autoFocus: false,
      data: order
    });
  }
}